import { useCallback, useState } from "react";
import { appendUserSubscribe, removeUserSubscribe } from "../core/api";
import { getRequestBody } from "../util";

export function useSubscribe() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const requestScribe = useCallback(async (mSeq) => {
    const requestBody = getRequestBody({ mSeq });

    setResponseMessage('');
    setIsLoading(true);

    try {
      const res = await appendUserSubscribe(requestBody);

      setResponseMessage(res.resultMsg);

      if (res.returnInt === 1) {
        setResponseMessage('구독했습니다.');
      } else if (res.returnInt === -3) {
        setResponseMessage('본인의 서재는 구독할 수 없습니다.');
        setError(true);
      } else {
        setError(true);
      }
    } catch {
      setError(true);
    }

    setIsLoading(false);
  }, []);

  return { isLoading, error, requestScribe, responseMessage, setResponseMessage };
}

export function useUnSubscribe() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseMessageUs, setResponseMessageUs] = useState('');

  const requestUnScribe = useCallback(async (mSeq) => {
    const requestBody = getRequestBody({ mSeq });

    setResponseMessageUs('');
    setIsLoading(true);

    try {
      const res = await removeUserSubscribe(requestBody);

      setResponseMessageUs(res.resultMsg);

      if (res.returnInt === 1) {
        setResponseMessageUs('구독을 취소했습니다.');
      } else if (res.returnInt === -3) {
        setResponseMessageUs('본인의 서재는 구독을 취소할 수 없습니다.');
        setError(true);
      } else {
        setError(true);
      }
    } catch {
      setError(true);
    }

    setIsLoading(false);
  }, []);

  return { isLoading, error, requestUnScribe, responseMessageUs, setResponseMessageUs };
}
