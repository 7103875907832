import { useEffect, useState } from "react";
import styled from "styled-components";
import SetProfile from "./SetProfile";
import SelectBox from "./SelectBox";
import { useMediaQuery } from "react-responsive";

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: ${(props) => (props.borderTop ? `1.5px solid ${props.theme.gray800}` : "none")};
  border-bottom: ${(props) => (props.borderBottom ? "1.5px" : "1px")} solid ${(props) => (props.borderBottom ? props.theme.gray800 : "#C0C5D1")};
  height: ${(props) => (props.size === "lg" ? "128px" : props.size === "medium" ? "120px" : props.size === "xlg" ? "152px" : "max-content")};
  min-height: 76px;
  h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: auto;
    /* min-height: 76px; */
    background-color: #f7f8fa;
    margin-right: 24px;
    color: #656975;
    strong {
      color: ${(props) => props.theme.point01};
      margin-top: 3px;
      margin-left: 3px;
    }

    @media ${({ theme }) => theme.device.tablet} {
      width: 100px;
      min-width: 100px;

      margin-right: 8px;
    }
  }
  input {
    display: block;
    margin: auto 0px;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.gray400};
    border-radius: 0;
    outline: none;
    width: 436px;
    font-size: 16px;
    ::placeholder {
      color: ${(props) => props.theme.gray400};
      @media ${({ theme }) => theme.device.tablet} {
        font-size: 15px;
      }
    }
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    @media ${({ theme }) => theme.device.tablet} {
      width: calc(100% - 200px);
      font-size: 15px;
    }
    @media ${({ theme }) => theme.device.mobilelg} {
      width: calc(100% - 120px);
    }
  }
  #phoneWrap {
    display: flex;
    width: 528px;
    height: 70%;
    margin: auto 0px;
    @media ${({ theme }) => theme.device.labtop} {
      width: calc(100% - 250px);
    }
    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 120px);
    }
    #phone {
      display: block;
      width: 436px;
      @media ${({ theme }) => theme.device.tablet} {
        width: 100%;
      }
      /* @media ${({ theme }) => theme.device.mobilelg} {
        width: calc(100% - 210px);
      }
      @media ${({ theme }) => theme.device.mobile} {
        width: calc(100% - 183px);
      } */
    }

    button.phoneAuth {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 32px;
      background-color: #fff;
      margin: auto 0px;
      color: ${(props) => props.theme.point01};
      border: 1px solid ${(props) => props.theme.point01};
      border-radius: 4px;
      margin-left: 8px;
      cursor: pointer;
      font-size: 14px;
      line-height: 17px;
      @media ${({ theme }) => theme.device.tablet} {
        margin: 8px 0 0 0;

        width: 84px;
        height: 32px;
        font-size: 14px;
      }
    }
  }
  textarea {
    height: 112px;
    width: 436px;
    outline: none;
    margin: auto 0;
    font-size: 16px;
    padding: 6px 8px;
    resize: none;
    border: 1px solid #9a9ba7;
    border-radius: 4px;
    ::placeholder {
      font-size: 16px;
      color: ${(props) => props.theme.gray400};
    }
    /* @media ${({ theme }) => theme.device.tablet} {
      width: 300px;
    }
    @media ${({ theme }) => theme.device.mobilelg} {
      width: 225px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: 200px;
    } */
    @media ${({ theme }) => theme.device.tablet} {
      width: calc(100% - 200px);
    }
    @media ${({ theme }) => theme.device.mobilelg} {
      width: calc(100% - 120px);
    }
  }
  input#file,
  input#file2 {
    display: none;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 36px;
    border: 1px solid ${(props) => props.theme.gray600};
    margin: auto 0;
    border-radius: 4px;
    cursor: pointer;
    &#file,
    &#file2 {
      width: 84px;
      min-width: 84px;
      font-family: "pretendard-SemiBold";
      font-size: 14px;
      line-height: 17px;
      color: #4e4b4b;

      /* &:not(.profile) {
        @media ${({ theme }) => theme.device.mobilelg} {
          padding: 0px !important;
          width: 84px;
          height: 28px;
          text-align: center;
        }
      } */
    }
    &#file {
      height: 32px;
    }
    &#file2 {
      height: 100%;
    }
    /* @media ${({ theme }) => theme.device.mobilelg} {
      width: 50px;
      height: 32px;
    } */
    /* @media ${({ theme }) => theme.device.mobile} {
      padding: 5px 10px !important;
    } */
  }
  span {
    margin: auto 0px;
    border: none;
    /* border-bottom: 1px solid ${(props) => props.theme.gray400}; */
    width: max-content;
    color: ${(props) => props.theme.gray400};
    padding: 6px 0px;
    margin-left: 16px;
    &.fileSpan {
      display: flex;
      align-items: center;
      padding: 0%;
      em {
        display: inline-block;
      }
      @media screen and (max-width: 540px) {
        margin-left: 10px;
        > .left {
          display: inline-block;
          max-width: 110px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  div.terms {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 32px;
    margin: auto 0px;
    font-size: 14px;
    line-height: 17px;
    color: #4e4b4b;
    border: 1px solid ${(props) => props.theme.gray600};
    border-radius: 4px;
    cursor: pointer;
    @media ${({ theme }) => theme.device.tablet} {
      width: 84px;
      height: 32px;
      font-size: 14px;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    height: ${(props) => (props.size === "lg" ? "114px" : props.size === "medium" ? "100px" : props.size === "xlg" ? "132px" : "max-content")};
    min-height: 56px;
    font-size: 15px;
    line-height: 24px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  &.select {
    width: 436px;
  }
  span.unChangable {
    /* font-size: 16px; */
    color: ${(props) => props.theme.gray1000};
    line-height: 1.5;
    border: none;
    margin-left: 0px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 150px;
    &.radio {
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;
      width: calc(100% - 110px);
      gap: 6px 7px;
    }
    &.select {
      width: calc(100% - 120px);
    }
  }
`;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  width: 528px;
  @media ${({ theme }) => theme.device.tablet} {
    width: calc(100% - 120px);
  }
  input {
    margin: 0px;
    width: 100%;
  }
  em {
    display: block;
    height: 24px;
    /* margin: 5px 0 0; */
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    color: #fa2337;
  }
`;
const CheckBox = styled.div`
  margin-right: 10px;
  @media screen and (max-width: 540px) {
    margin-right: 5px;
  }
  input {
    display: none;
    &:checked + label {
      color: #000;
      border: 1px solid #000;
    }
  }
  label {
    font-size: 16px;
    border-radius: 100px;
    width: max-content;
    padding: 10px 20px;
    border: 1px solid #d9d9d9;
    color: #999;
    @media screen and (max-width: 540px) {
      padding: 3px 8px;
    }
  }
`;

export default function HorizontalInput({
  kind,
  type,
  title,
  placeholder,
  register,
  reset,
  array,
  required,
  borderTop,
  borderBottom,
  errors,
  text,
  checkedItem,
  onInput,
  fileName,
  value,
  changeValue,
}) {
  const [checkedIndex, setCheckedIndex] = useState();
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  useEffect(() => {
    if (array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === checkedItem) {
          setCheckedIndex(i);
        }
      }
    }
  }, [checkedItem]);

  const [inputValue, setInputValue] = useState("");
  const phoneNumHandleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };
  useEffect(() => {
    if (inputValue.length === 10) {
      setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (inputValue.length === 13) {
      setInputValue(inputValue.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
    }
  }, [inputValue]);

  return (
    <>
      {kind === "base" ? (
        <Row borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <input id={kind} type={type} placeholder={placeholder} {...register} />
        </Row>
      ) : null}

      {kind === "password" ? (
        <Row size="lg" borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <FlexCol>
            <input type={type} placeholder={placeholder[0]} {...register[0]} autoComplete="off" />
            <em>{errors?.password?.message}</em>
            <input type={type} placeholder={placeholder[1]} {...register[1]} autoComplete="off" />
            <em>{errors?.password1?.message}</em>
          </FlexCol>
        </Row>
      ) : null}

      {kind === "textarea" ? (
        <Row size="xlg" borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <textarea id={kind} type={type} placeholder={placeholder} {...register} />
        </Row>
      ) : null}

      {kind === "file" ? (
        <Row size={type === "profile" ? "medium" : ""} borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          {type !== "profile" ? (
            <>
              <input id="file" type="file" accept="image/jpeg, image/jpg, image/png" onInput={onInput} {...register} />
              <label htmlFor="file" id="file">
                파일 선택
              </label>
              <span className="fileSpan">
                <em className="left">{fileName.split(".")[0]}</em>
                <em>.{fileName.split(".")[1]}</em>
              </span>
            </>
          ) : (
            <SetProfile text={text} register={register} reset={reset} errors={errors} />
          )}
        </Row>
      ) : null}

      {kind === "phone" ? (
        <Row size={tablet ? "lg" : ""} borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <div id="phoneWrap">
            <input id={kind} type={type} placeholder={placeholder} {...register} onChange={phoneNumHandleChange} value={inputValue} />
            {/* <button className="phoneAuth pretendardSB" type="button">
              전화 인증
            </button> */}
          </div>
        </Row>
      ) : null}

      {kind === "radio" ? (
        <Row borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <Flex className="radio">
            {array.map((item, i) => (
              <CheckBox key={i}>
                <input
                  id={item}
                  type={type}
                  name={type}
                  value={item}
                  checked={checkedIndex === i}
                  onClick={(e) => {
                    setCheckedIndex(i);
                  }}
                  onChange={(e) => {}}
                  {...register}
                />
                <label htmlFor={item}>{item}</label>
              </CheckBox>
            ))}
          </Flex>
        </Row>
      ) : null}

      {kind === "select" ? (
        <Row borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <Flex className="select">
            <SelectBox title={title} array={array} register={{ ...register }} value={value} changeValue={changeValue} />
          </Flex>
        </Row>
      ) : null}

      {kind === "text" ? (
        <Row borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <Flex>
            <span className="unChangable">{text}</span>
          </Flex>
        </Row>
      ) : null}

      {kind === "inputText" ? (
        <Row borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <FlexCol>
            <input id={kind} type={type} defaultValue={text} placeholder={placeholder} {...register} />
            <em>{errors?.nickName?.message}</em>
          </FlexCol>
        </Row>
      ) : null}

      {kind === "isAgree" ? (
        <Row borderTop={borderTop} borderBottom={borderBottom}>
          <h5>
            {title}
            <strong>{required ? "*" : ""}</strong>
          </h5>
          <div className="terms pretendardSB">약관 보기</div>
        </Row>
      ) : null}
    </>
  );
}
