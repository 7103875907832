import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { themeState } from "../atoms";
import arrow from "../images/components/footer/arrow_up.svg";
import arrowFamilySiteDefualt from "../images/components/footer/arrow_familySite_off.svg";
import arrowFamilySiteHover from "../images/components/footer/arrow_familySite_on.svg";
import { ReactComponent as Logo } from "../images/components/footer/tovsoft_logo.svg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const FooterWrap = styled.footer`
  width: 100%;
  min-height: 104px;
  height: fit-content;
  /* background: ${(props) => props.theme.gray100}; */
`;

const FooterNavi = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  min-width: 1200px;
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    padding: 16px 16px 15px;
    margin: 0 auto;
    > ul {
      display: flex;
      gap: 16px;
      font-size: 14px;
      font-family: "Pretendard-Medium";
      color: #555969;
      li:not(:last-child) {
        padding-right: 16px;
        border-right: 1px solid #e0e0e0;
      }
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    .inner {
      width: 100%;
      padding: 18px 16px;
      > ul {
        font-size: 12px;
        line-height: 20px;
        gap: 6px;
        li:not(:last-child) {
          padding-right: 6px;
        }
      }
    }
  }
`;
const FamilySiteWrap = styled.div`
  position: relative;
  .site_relation {
    width: 134px;
    height: 32px;
    padding: 0 16px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    background: url(${arrow}) center right 10px no-repeat;
    color: #777373;
    font-size: 13px;
    text-align: left;
    cursor: pointer;
  }
  .site_list {
    display: none;
    position: absolute;
    left: 0;
    bottom: 34px;
    width: 134px;
    border: none;
    border-radius: 5px;
    background-color: ${(props) => props.theme.gray200};
    overflow: hidden;
    &[data-list="true"] {
      display: block;
    }
    li {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 16px;
      font-size: 13px;
      color: ${(props) => props.theme.gray400};
      text-align: left;
      cursor: pointer;
      &:not(:first-child) {
        border-top: 1px solid ${(props) => props.theme.gray200};
      }
      &:hover {
        color: ${(props) => props.theme.gray500};
        & .arrow {
          background: url(${arrowFamilySiteHover}) center/100% no-repeat;
        }
      }
      .arrow {
        width: 12px;
        height: 12px;
        margin-left: 5px;
        background: url(${arrowFamilySiteDefualt}) center/100% no-repeat;
        &:hover {
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 8px;
    .site_relation {
      width: 100px;
      padding: 0 7px;
      font-size: 12px;
    }
    .site_list {
      width: 100px;
      padding: 0 10px;
      font-size: 12px;
      li {
        padding: 0;
        font-size: 12px;
      }
    }
  }
`;

const FooterCopyRight = styled.div`
  min-width: 1200px;
  width: 1200px;
  padding: 17px 16px 15px;
  margin: 0 auto;
  font-family: "Pretendard-Medium";
  line-height: 21px;
  h4 {
    font-size: 13px;
    color: #303441;
  }
  p {
    margin-top: 8px;
    font-size: 12px;
    color: #9a9ba7;
    &.copyRight {
      margin: 0px 0 16px;
      color: #c8cad2;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    padding: 16px 16px 15px;

    .openTextBtn {
      font-family: "Pretendard-Medium";
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #303441;
      cursor: pointer;
    }
    h4 {
      font-size: 12px;
    }
  }
`;

export default function Footer() {
  const [theme, setTheme] = useRecoilState(themeState);
  const [list, setList] = useState(false);
  const hiddenH4 = useRef("");
  const onList = (obj) => {
    if (list) setList(false);
    else setList(true);
  };
  const onWebLink = (url) => {
    setList(false);
    window.open("https://" + url, "_blank");
  };
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  const FamilySite = () => {
    return (
      <FamilySiteWrap>
        <button className="site_relation" onClick={onList}>
          관련 사이트
        </button>
        <ul className="site_list" data-list={list}>
          <li
            onClick={() => {
              onWebLink("www.tovsoft.com");
            }}
          >
            토브소프트<span className="arrow"></span>
          </li>
          {/*<li
                onClick={() => {
                  onWebLink();
                }}
              >
                템플릿 스토어<span className="arrow"></span>
              </li>
              <li
                onClick={() => {
                  onWebLink();
                }}
              >
                컴포넌트 스토어<span className="arrow"></span>
              </li>*/}
        </ul>
      </FamilySiteWrap>
    );
  };

  return (
    <FooterWrap>
      <FooterNavi>
        <div className="inner">
          <ul>
            <li>
              <Link to={`/`}>이용약관</Link>
            </li>
            <li>
              <Link to={`/`}>개인정보처리방침</Link>
            </li>
            <li>
              <Link to={`/request`}>콘텐츠 제작 문의</Link>
              {/* <a href="mailto:help@tovsoft.com"></a> */}
            </li>
            <li>
              <Link to={`/solution`}>솔루션 문의</Link>
              {/* <a href="mailto:help@tovsoft.com"></a> */}
            </li>
            <li>
              <Link to={`/faq`}>FAQ</Link>
              {/* <a href="mailto:help@tovsoft.com"></a> */}
            </li>   
            <li>
              <Link to={`/feedback`}>1:1문의</Link>
              {/* <a href="mailto:help@tovsoft.com"></a> */}
            </li>                      
          </ul>
          {!tablet && <FamilySite />}
        </div>
      </FooterNavi>
      <FooterCopyRight>
        {!tablet ? (
          <div>
            <h4>
              (주)토브소프트ㅣ서울시 강남구 도곡로 127 제노아빌딩 3층ㅣ 대표: 설재갑ㅣ개인정보관리책임자: 박문성ㅣ사업자등록번호: 893-88-00074ㅣ고객센터: 02-6959-2716ㅣ
              <a href="mailto:help@tovsoft.com">help@tovsoft.com</a>
            </h4>

            <p>(주)토브소프트는 통신판매중개자이며, 통신판매의 당사자가 아닙니다. 따라서 (주)토브소프트는 콘텐츠, 거래정보 및 거래에 대하여 책임을 지지 않습니다.</p>
            <p className="copyRight">Copyright ⓒ TOVSOFT. All rights reserved.</p>
          </div>
        ) : (
          <div>
            <FamilySite />
            <h4>
              (주)토브소프트ㅣ서울시 강남구 도곡로 127 제노아빌딩 3층
              <br />
              대표: 설재갑ㅣ개인정보관리책임자: 박문성
              <br />
              사업자등록번호: 893-88-00074 ㅣ 고객센터: 02-6959-2716
              <br />
              <a href="mailto:help@tovsoft.com">help@tovsoft.com</a>
            </h4>
            <p>(주)토브소프트는 통신판매중개자이며, 통신판매의 당사자가 아닙니다. 따라서 (주)토브소프트는 콘텐츠, 거래정보 및 거래에 대하여 책임을 지지 않습니다.</p>

            <p className="copyRight">Copyright ⓒ TOVSOFT. All rights reserved.</p>
          </div>
        )}
      </FooterCopyRight>
    </FooterWrap>
  );
}

const UlWrap = styled.div`
  /* width: 100%; */
  min-height: 104px;
  height: fit-content;
  /* background: ${(props) => props.theme.gray100}; */
`;
