import { useRecoilState } from "recoil";
import styled from "styled-components";
import { modalState } from "../atoms";
import { useMediaQuery } from "react-responsive";
import XBtn from "../images/common/modal/x.svg";
import { appendEbookComplain } from "../core/api";
import { useNavigate } from "react-router-dom";

const ModalWrap = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  z-index: 50;
  backdrop-filter: blur(20px);
`;
const Modal = styled.div`
  position: relative;
  /* height: 184px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  overflow: hidden;
  &.qrImgPopup {
    width: 192px;
    /* height: 298px; */
    overflow: hidden;
    h2 {
      display: block;
      margin: 16px;
      font-family: "Pretendard-Medium";
      font-size: 22px;
      line-height: 26px;
      color: #303441;
    }
  }
  p {
    margin: 32px auto 72px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #303441;
    text-align: center;
  }
  button {
    width: 100%;
    height: 40px;
    border: none;
    border-top: 1px solid ${(props) => props.theme.gray300};
    background-color: #fff;
    font-family: "pretendard-SemiBold";
    color: ${(props) => props.theme.gray600};
    font-size: 18px;
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.point01};
      background-color: ${(props) => props.theme.gray100};
    }
  }
  textarea {
    width: calc(100% - 32px);
    height: 88px;
    background-color: ${(props) => props.theme.gray100};
    border: 1px solid ${(props) => props.theme.gray200};
    border-radius: 4px;
    outline: none;
    padding: 8px;
    margin: 0 auto 56px;
    resize: none;
    ::placeholder {
      font-size: 16px;
      color: ${(props) => props.theme.gray400};
    }
  }
`;
const Head = styled.div`
  /* position: absolute; */
  /* top: 0px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  font-size: 18px;
  h2 {
    font-family: "pretendard-SemiBold";
    font-size: 22px;
    line-height: 26px;

    color: ${(props) => props.theme.gray1000};
    margin-left: 16px;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    background: url(${XBtn}) center/28px auto no-repeat;
    margin-right: 4px;
    cursor: pointer;
  }
`;

export default function ModalPopup({
  title,
  text,
  width,
  children,
  input,
  id = null,
  itemType,
  isDark = false,
  qrImgPopup,
  commentInfo,  
}) {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const mobile = useMediaQuery({ query: `(max-width: 520px)` });
  const navigate = useNavigate();

  const onClick = async () => {
    if (title === "신고하기") {
      if (input !== "") {
        let formdata = new FormData();
        formdata.append("itemSeq", id);
        formdata.append("itemType", itemType);
        formdata.append("complain", input);
        formdata.append("cmtTf", commentInfo.cmtTf);
        formdata.append("cmtSeq", commentInfo.cmtSeq);

        const result = await appendEbookComplain(formdata);
        const returnInt = result.returnInt;
        if (returnInt === 1) alert("관리자에게 전달 하였습니다.");
        else alert("일시적인 오류가 발생하였습니다. 잠시후 다시 시도해주세요.");

        window.location.reload();
      } else alert("신고할 내용을 적어주세요.");
    } else navigate("/");
  };

  return (
    <ModalWrap
      mobile={mobile ? true : null}
      style={{ backgroundColor: isDark ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.23)" }}
      // className="modalWrap"
    >
      <Modal
        style={{
          width: width + "px",
        }}
        className={qrImgPopup ? "qrImgPopup" : ""}
      >
        {title ? (
          <Head>
            <h2>{title}</h2>
            <div className="close" onClick={() => setIsOpen(false)}>
              <img src={XBtn} alt="" />
            </div>
          </Head>
        ) : null}
        {text ? <p>{text}</p> : children}
        {qrImgPopup ? "" : <button onClick={onClick}>확인</button>}
      </Modal>
    </ModalWrap>
  );
}
