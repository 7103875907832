import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ThemeProvider } from "styled-components";
import { themeState } from "./atoms";
import GlobalStyle from "./GlobalStyle";
import Home from "./routes/Home";
import theme, { dark, light } from "./theme";
import EbookDetail from "./routes/EbookDetail";
import Faq from "./routes/Faq";
import MyLibrary from "./routes/MyLibrary";
import Login from "./routes/Login";
import Feedback from "./routes/Feedback";
import Account from "./routes/Account";
import Download from "./routes/Download";
import SearchResults from "./routes/SearchResults";
import MoreEbooks from "./routes/MoreEbook";
import PrevLogin from "./routes/PrevLogin";
import SSOResult from "./routes/SSOResult";
import YourLibrary from "./routes/YourLibrary";
import ContentList from "./routes/ContentList";
import Guide from "./routes/Guide";
import Event from "./routes/Event";
import Request from "./routes/Request";
import Solution from "./routes/Solution";
import ContentGuide from "./routes/ContentGuide";
import SignUpCompleted from "./routes/SignUpCompleted";
import Template from "./routes/Template";
import View from "./routes/View";
import SearchTemplateResults from "./routes/SearchTemplateResults";
import TemplateDetail from "./routes/TemplateDetail";
import SSOCheck from "./routes/SSOCheck";
import SSOFail from "./routes/SSOFail";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 10000,
      refetchOnWindowFocus: false,
      // refetchInterval: 60000,
    },
  },
});

function App() {
  const themeMode = useRecoilValue(themeState);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="ebook/:id" element={<EbookDetail />}></Route>
            <Route path="faq" element={<Faq />}></Route>
            <Route path="library" element={<MyLibrary />}></Route>
            <Route path="library/:id" element={<MyLibrary />}></Route>
            <Route path="login" element={<Login />}></Route>
            <Route path="prevlogin" element={<PrevLogin />}></Route>
            <Route path="feedback" element={<Feedback />}></Route>
            <Route path="account" element={<Account />}></Route>
            <Route path="download" element={<Download />}></Route>
            <Route path="search" element={<SearchResults />}></Route>
            <Route path="searchTemplate" element={<SearchTemplateResults />}></Route>
            <Route path="moreEbook" element={<MoreEbooks />}></Route>
            <Route path="contents" element={<ContentList />}></Route>
            <Route path="sso_result" element={<SSOResult />}></Route>
            <Route path="yourLibrary/:mSeq" element={<YourLibrary />}></Route>
            <Route path="guide" element={<Guide />}></Route>
            <Route path="event" element={<Event />}></Route>
            <Route path="request" element={<Request />}></Route>
            <Route path="solution" element={<Solution />}></Route>
            <Route path="contentGuide" element={<ContentGuide />}></Route>
            <Route path="SignUpCompleted" element={<SignUpCompleted />}></Route>
            <Route path="template" element={<Template />}></Route>
            <Route path="template/:id" element={<TemplateDetail />}></Route>
            <Route path="sso_check" element={<SSOCheck />}></Route>
            <Route path="sso_fail" element={<SSOFail />}></Route>
            <Route path="view" element={<View />}></Route>
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
