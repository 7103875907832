import styled from "styled-components";
import Icon_Loading from "../images/components/loading/icon_Loading.svg";

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 50;
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 300px;
  height: 114px;
  p {
    font-family: "Pretendard-SemiBold";
    font-size: 28px;
    color: ${(props) => props.theme.bgColor};
  }
`;
const Icon = styled.div`
  width: 56px;
  height: 56px;
  background: url(${Icon_Loading}) center no-repeat;
`;

export default function Loading() {
  return (
    <Wrap>
      <Inner>
        <Icon />
        <p>잠시만 기다려주세요.</p>
      </Inner>
    </Wrap>
  );
}
