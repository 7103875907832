export default function Login() {
  return (
    <div>
      <iframe
        src="https://jikji.tovsoft.com/login.do"
        width="100%"
        height="850px"
      ></iframe>
    </div>
  );
}
