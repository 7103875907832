import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Comment from "./Comment";
import Xbtn from "../images/common/modal/x.svg";
import { mobileCommentsAtom } from "../atoms";
import { useRecoilState } from "recoil";
import { useState } from "react";
import { appendEbookComment } from "../core/api";
import { confirmLogin } from "../util";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(motion.div)`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;
const CommentsSection = styled(motion.div)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  padding: 10px 24px;
`;
const Bar = styled.div`
  width: 56px;
  height: 6px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.gray300};
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid ${(props) => props.theme.gray300};
  em {
    font-weight: bold;
  }
  img {
    cursor: pointer;
  }
`;
const CommentForm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  margin-top: 16px;
  input {
    width: calc(100% - 68px);
    outline: none;
    border: 1px solid ${(props) => props.theme.gray400};
    border-radius: 4px;
    padding-left: 16px;
  }
  button {
    width: 60px;
    height: 32px;
    margin-left: 8px;
    background-color: ${(props) => props.theme.gray600};
    color: #fff;
    border-radius: 4px;
    outline: none;
    border: none;
  }
`;
const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  @media ${({ theme }) => theme.device.tablet} {
    overflow: auto;
  }
`;

export default function MobileComments({ lists, id }) {
  const [mobileComments, setMobileComments] = useRecoilState(mobileCommentsAtom);
  let formdata = new FormData();
  formdata.append("eSeq", id);

  const body = document.querySelector("body");
  const goback = () => {
    setMobileComments(false);
    body.style.overflow = "auto";
  };
  const [userComment, setUserComment] = useState("");
  const navigate = useNavigate();

  const addComment = async () => {
    if (userComment !== "") {
      formdata.append("comment", userComment);
      const result = await appendEbookComment(formdata);
      const returnInt = result.returnInt;
      if (returnInt == 0) {
        confirmLogin(navigate);
      }
      window.location.reload();
    } else return;
  };

  return (
    <Wrapper>
      <AnimatePresence>
        <CommentsSection
          initial={{ bottom: "-50%" }}
          animate={{ bottom: 0 }}
          transition={{ duration: 0.5, type: "spring" }}
        >
          <Bar />
          <Head>
            <span>
              댓글 <em>{lists.length}</em>
            </span>
            <img src={Xbtn} alt="" onClick={goback} />
          </Head>
          <CommentForm>
            <input
              type="text"
              placeholder="댓글을 입력해주세요"
              onChange={(e) => {
                setUserComment(e.target.value);
              }}
            />
            <button onClick={addComment}>등록</button>
          </CommentForm>
          <CommentList>
            {lists &&
              lists.map((list, i) => (
                <Comment
                  username={list.nickName}
                  date={list.regDate.slice(0, 10)}
                  text={list.comment}
                  border={false}
                  bgurl={list.thumbUrl}
                  mSeq={list.mSeq}
                  key={i}
                />
              ))}
          </CommentList>
        </CommentsSection>
      </AnimatePresence>
    </Wrapper>
  );
}
