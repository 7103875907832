export const bannerImg = {
  config: [
    "SET_01.jpg",
    "SET_02.jpg",
    "SET_03.jpg",
    "SET_04.jpg",
    "SET_05.jpg"
  ],
  faq: [
    "FAQ_01.jpg",
    "FAQ_02.jpg",
    "FAQ_03.jpg",
    "FAQ_04.jpg",
    "FAQ_05.jpg"
  ],
  feedback:[
    "FB_01.jpg",
    "FB_02.jpg",
    "FB_03.jpg",
    "FB_04.jpg",
    "FB_05.jpg"
  ],
  moreEbooks:[
    "MR_01.jpg",
    "MR_02.jpg",
    "MR_03.jpg",
    "MR_04.jpg",
    "MR_05.jpg"
  ],
  myLibrary:[
    "ML_01.jpg",
    "ML_02.jpg",
    "ML_03.jpg",
    "ML_04.jpg",
    "ML_05.jpg"
  ],
  searchResult:[
    "SC_01.jpg",
    "SC_02.jpg",
    "SC_03.jpg",
    "SC_04.jpg",
    "SC_05.jpg"
  ]
}