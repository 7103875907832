import styled from "styled-components";
import HorizontalInput from "../components/HorizontalInput";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import { useForm } from "react-hook-form";
import EventBanner from "../components/EventBanner";
import BlankBox from "../components/BlankBox";
import Container from "../components/Container";
import SelectBox from "../components/SelectBox";
import { useMediaQuery } from "react-responsive";
import { createContext, useEffect, useState } from "react";
import { changeMemberInfo, deleteMemberPhoto, getMemberInfo, getUserTypeSelect } from "../core/api";
import { getValue } from "@testing-library/user-event/dist/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const Main = styled.main`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;
const Form = styled.form`
  /* margin-top: 40px; */
`;
const Btns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  button,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    height: 36px;
    border-radius: 6px;
    border: none;
    margin: 0px 8px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 40px;
  }
  span {
    background-color: ${(props) => props.theme.gray300};
    color: ${(props) => props.theme.gray600};
  }
  button {
    background-color: ${(props) => props.theme.gray600};
    color: #fff;
  }
`;
//사진 삭제를 위해 useContext()를 사용
export const DellPhotoState = createContext();
export default function Account() {
  const [userId, setUserId] = useState("");
  const [userNickName, setUserNickName] = useState("");
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [loginType, setLoginType] = useState("");
  const [isDellPhoto, setIsDellPhoto] = useState(false);

  const getUserInfo = async () => {
    const result = await getMemberInfo();
    console.log("🚀 ~ file: Account.jsx:60 ~ getUserInfo ~ result", result);
    if (result.returnInt !== 1) {
      alert("잘못된 접근입니다. 로그인을 확인해주세요");
      navigate(`/`);
    }
    setUserName(result.row.userName);
    setUserNickName(result.row.nickName);
    setUserId(result.row.userId);
    setUserType(result.row.userType);
    setLoginType(result.row.loginType);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

   // 가입유형 가져오기
  const userTypeInfo = useQuery(["getUserTypeSelect"], () => getUserTypeSelect());
  const userTypeData = userTypeInfo.data?.rows;
  
  const [userTypeArray, setUserTypeArray] = useState([]);
  useEffect(() => {
    //SelectBox 데이터
    if( userTypeInfo.data ) {
      const _userTypeArray = userTypeData?.map((type) => {
        return Object.values(type)[1];
      });
      setUserTypeArray(_userTypeArray);
    }
  }, [userTypeInfo.data]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      nickName: "",
      password: "",
      password1: "",
      phone: "",
      subscriptionType: "",
      userPhoto: [],
    },
  });

  useEffect(() => {
    setValue("subscriptionType", userType);
    // setValue("subscriptionType2", userType);
    setValue("nickName", userNickName);
  }, [setValue, userType]);
  useEffect(() => {
    setValue("subscriptionType", userType);
    setValue("subscriptionType2", userType);
  }, [reset]);

  const onCancel = () => {
    navigate("/");
  };

  const changeValue = (type) => {
    setValue("subscriptionType", type);
    // console.log("changeValue", type);
  };

  const navigate = useNavigate();
  const onValid = async (form) => {
    console.log("🚀 ~ file: Account.jsx:108 ~ onValid ~ form", form);
    let formdata = new FormData();

    if (form.password) {
      if (form.password !== form.password1) {
        return setError("password1", {
          message: "비밀번호가 일치하지 않습니다.",
        });
      }
      formdata.append("userPw", form.password);
    }
    if (!form.nickName) {
      return setError("nickName", { message: "닉네임을 입력하세요." });
    }

    console.log(userType);
    let typeCode;
    // switch (form.subscriptionType) {
    //   case "학생":
    //     typeCode = "S";
    //     break;
    //   case "선생님":
    //     typeCode = "T";
    //     break;
    //   case "기업":
    //     typeCode = "C";
    //     break;
    //   case "일반":
    //     typeCode = "I";
    //     break;
    //   default:
    //     typeCode = "P";
    // }

    const userTypeAryData = userTypeData.find((type) => {
      if( type.name === form.subscriptionType) return true;
    });

    typeCode = userTypeAryData.code;

    const onlyNum = form.phone.split("-").join("");

    // const regExp = /[a-zA-Z]/g;
    // console.log("닉네임", form.nickName);
    // if (regExp.test(form.nickName)) {
    //   console.log('영어')
    // // } else if () {
    // } else {
    //   console.log('에러')
    // }

    formdata.append("userName", userName);
    formdata.append("nickName", form.nickName);
    formdata.append("userPhone", onlyNum);
    formdata.append("userTypeCode", typeCode);
    if (!form.userPhoto[0] && isDellPhoto) {
      console.log("사진", form.userPhoto[0]);
      const deleteResult = await deleteMemberPhoto();
      console.log("deleteMemberPhoto result", deleteResult);
    } else if (form.userPhoto[0]) {
      formdata.append("photoFile", form.userPhoto[0]);
      console.log("changeMemberInfo result", form.userPhoto[0]);
    }

    const result = await changeMemberInfo(formdata);
    if (result) {
      const returnInt = result.returnInt;
      if (returnInt == -2) {
        return setError("userPhoto", { message: result.resultMsg });
      } else if (returnInt == 1) {
        alert("계정이 변경되었습니다.^^");
        navigate("/");
      } else {
        console.log("returnInt", result.resultMsg);
      }
    }
  };

  const onChange = () => {
    clearErrors();
  };

  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Layout>
      <PageTitle title="계정 설정" kind="config" />
      <Main>
        <Container>
          <Form onSubmit={handleSubmit(onValid)} onChange={onChange}>
            <DellPhotoState.Provider value={{ setIsDellPhoto }}>
              <HorizontalInput
                kind="file"
                title="프로필 사진"
                type="profile"
                // text={userId}
                borderTop={true}
                text={userNickName.slice(0, 1)}
                register={register("userPhoto")}
                errors={errors}
                reset={reset}
              />
            </DellPhotoState.Provider>
            <HorizontalInput
              kind="text"
              title="이메일"
              type="text"
              text={`${userId} (${loginType} 회원)`}
              borderTop={false}
            />

            {loginType === "일반" ? (
              <HorizontalInput
                kind="password"
                title="비밀번호"
                type="password"
                placeholder={[
                  !tablet
                    ? "영문, 숫자 및 특수 기호를 포함하여 8자 이상 입력하세요."
                    : "새 비밀번호를 입력하세요.",
                  "입력하신 비밀번호를 확인하세요.",
                ]}
                register={[
                  register("password", {
                    required: { message: "비밀번호를 입력해주세요." },
                    minLength: {
                      value: 1,
                      message:
                        "영문, 숫자 및 특수 기호를 포함하여 8자 이상 입력하십시오.",
                    },
                    maxLength: {
                      value: 16,
                      message: "비밀번호는 최대 16글자 이하이어야 합니다.",
                    },
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@$%#&+^*=/~`()<>?,.;:'"_-])[A-Za-z0-9!@$%#&+^*=/~`()<>?,.;:'"_-]{8,16}$/,
                      message:
                        "비밀번호는 숫자, 영문, 특수문자를 포함한 8글자 이상이어야 합니다.",
                    },
                  }),
                  register("password1"),
                ]}
                errors={errors}
              />
            ) : null}

            <HorizontalInput kind="text" title="이름" type="text" text={userName} />
            <HorizontalInput
              kind="inputText"
              title="닉네임"
              type="text"
              text={userNickName}
              placeholder={userNickName == null ? "닉네임을 입력하세요." : ""}
              register={register("nickName", {
                required: {
                  message: "공백과 특수문자를 제외한 2글자 이상의 닉네임을 입력하세요.",
                },
                minLength: {
                  value: 2,
                  message: "닉네임은 최소 2글자 이상 입니다.",
                },
                maxLength: {
                  value: 10,
                  message: "닉네임은 최대 10글자 이하 입니다.",
                },
                pattern: {
                  value: /^[a-zA-Z0-9가-힣]{2,10}$/,
                  message: "닉네임은 공백과 특수문자를 제외한 2~10글자 입니다.",
                },
              })}
              errors={errors}
              required
            />
            <HorizontalInput
              kind="phone"
              title="전화번호"
              type="text"
              placeholder={
                tablet
                  ? "하이픈(-) 없이 전화번호 입력"
                  : "하이픈(-) 없이 전화번호를 입력해주세요."
              }
              register={register("phone")}
            />
            {/* <HorizontalInput
              kind="radio"
              title="구독유형"
              type="radio"
              array={["학생", "선생님", "기업", "일반", "출판사"]}
              register={register("subscriptionType")}
              checkedItem={userType}
              required
            /> */}
            <HorizontalInput
              kind="select"
              title="나의 직업"
              type="select"
              array={userTypeArray}
              register={register("subscriptionType")}
              value={userType}
              changeValue={changeValue}
            />
            <HorizontalInput kind="isAgree" title="약관 동의" borderBottom={true} />

            <Btns>
              <span onClick={onCancel}>취소</span>
              <button type="submit">확인</button>
            </Btns>
          </Form>
        </Container>
      </Main>
      {/* <EventBanner text="유료 회원만의 혜택을 누리세요." linkText="유료 회원 가입" /> */}
      <BlankBox height="40"></BlankBox>
    </Layout>
  );
}
