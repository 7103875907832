import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { mobileCommentsAtom } from "../atoms";
import { appendStoreComment } from "../core/api";
import { ReactComponent as MoreComments } from "../images/pages/EbookDetail/more_comments.svg";
import { confirmLogin, useScale } from "../util";
import CommentTemplate from "./CommentTemplate";
import TitleLine from "./TitleLine";
import arrowDown from "../images/common/button/button_arrow_down_black.svg";
import { useEffect } from "react";

const Wrap = styled.div`
  /* grid-column: span 2; */
  width: 100%;
  /* margin-top: 90px; */
  div.commentsHeader {
    position: relative;
    width: 100%;
    h5 {
      padding: 10px 0px;
      font-size: 18px;
      font-family: "Pretendard-Medium";
      color: ${(props) => props.theme.gray800};
      @media ${({ theme }) => theme.device.tablet} {
        font-size: 14px;
      }
      strong {
        margin-left: 3px;
        font-weight: bold;
      }
    }
    svg {
      cursor: pointer;
    }
    button {
      display: flex;
      align-items: center;
      position: absolute;
      top: 4px;
      right: 0;
      margin-left: auto;
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: #242424;
      cursor: pointer;
      .arrow {
        display: inline-block;
        width: 10px;
        height: 5px;
        margin-left: 4px;
        background: url(${arrowDown}) center / 100% auto no-repeat;
      }
      &.foldBtn {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  div.desktop {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    textarea {
      padding: 20px 10px;
      border-radius: 4px;
      font-size: 16px;
      ::placeholder {
        font-size: 16px;
      }
      outline: none;
      border: none;
      border: 1px solid ${(props) => props.theme.gray400};
      resize: none; /* 사용자 임의 변경 불가 */
    }
    button {
      width: max-content;
      font-size: 16px;
      padding: 5px 10px;
      border-radius: 6px;
      color: #fff;
      background-color: ${(props) => props.theme.gray600};
      border: none;
      align-self: end;
      margin-top: 8px;
      cursor: pointer;
    }
    .button {
      width: max-content;
      font-size: 16px;
      padding: 5px 10px;
      border-radius: 6px;
      color: #fff;
      background-color: ${(props) => props.theme.gray600};
      border: none;
      align-self: end;
      margin-top: 8px;
      cursor: pointer;
    }
  }
  @media ${({ theme }) => theme.device.mobilelg} {
    margin-top: 20px;
  }
`;

const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* &.fold_off {
    width: 100%;
    height: 176px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  } */
`;

export default function CommentsSectionTemplate({ data, id, openComplain }) {

  const location = useLocation();
  let cpCmtSeq = getValue(location, "cmtseq");
  cpCmtSeq = parseInt(cpCmtSeq);
  
  const [selectedCommentIdx, setSelectedCommentIdx] = useState(0); // 선택된 댓글의 인덱스


  let formdata = new FormData();
  formdata.append("itemSeq", id); //템플릿은 콤포넌트와 함께 코멘트 기능을 공유하기 때문에 itemSeq로 통일.
  formdata.append("itemType", 'T'); //템플릿은 'T'로 설정. 콤포넌트는 'C'로 설정. 
  const body = document.querySelector("body");
  // 반응형
  const tablet = useMediaQuery({ query: `(max-width: 767px)` });
  const mobileLg = useMediaQuery({ query: `(max-width: 540px)` });
  const mobile = useMediaQuery({ query: `(max-width: 480px)` });
  const mobileBg = useScale(480);
  const navigate = useNavigate();

  const [mobileComments, setMobileComments] = useRecoilState(mobileCommentsAtom);
  const showCommentPopup = () => {
    setMobileComments(true);
    body.style.overflow = "hidden";
  };
  const [userComment, setUserComment] = useState("");
  const addComment = async () => {
    if (userComment !== "") {
      formdata.append("comment", userComment);
      const result = await appendStoreComment(formdata);
      const returnInt = result.returnInt;
      if (returnInt == 0) {
        confirmLogin(navigate);
      }
      // console.log("result", result);
      window.location.reload();
    } else return;
  };
  const textarea = useRef();
  const handleResizeHeight = () => {
    textarea.current.style.height = "auto";
    textarea.current.style.height = textarea.current.scrollHeight + "px";
  };

  const [foldState, setFoldState] = useState(true);

  const folding_open = (e) => {
    setFoldState((perv) => !perv);
  };

  useEffect(() => {
    setFoldState(true);
    checkMore();
  }, [data]);
  
  useEffect(() => {
    checkMore();
  }, [tablet]);

  const [isMore, setIsMore] = useState(true);
  const checkMore = () => {
    (!tablet && data.length > 3) || (tablet && data.length > 1)
      ? setIsMore(true)
      : setIsMore(false);
  };
  return (
    <Wrap>
      <div className="commentsHeader">
        <TitleLine>댓글 {data.length}</TitleLine>
        {isMore && (
          <button onClick={folding_open} className={foldState ? "" : "foldBtn"}>
            {foldState ? "더보기" : "접기"}
            <span className="arrow"></span>
          </button>
        )}
        {/* <MoreComments
          width={10}
          height={14}
          style={{ margin: "10px" }}
          onClick={mobile || tablet ? () => showCommentPopup() : () => {}}
        /> */}
      </div>
      <div className="desktop">
        <textarea
          type="text"
          placeholder="댓글을 입력해주세요."
          ref={textarea}
          rows={1}
          onChange={(e) => {
            setUserComment(e.target.value);
            handleResizeHeight();
          }}
        />
        <button onClick={addComment}>등록</button>
      </div>

      <CommentList className={foldState ? "fold_off" : ""}>
        {!tablet &&
          foldState &&
          data
            .slice(0, 3)
            .map((list, i) => (
              <CommentTemplate
                cmtSeq={list.cmtSeq}
                username={list.nickName}
                date={list.regDate.slice(0, 10)}
                text={list.comment}
                border={false}
                bgurl={list.thumbUrl}
                key={i}
                mSeq={list.mSeq}
                setSelectedCommentIdx={setSelectedCommentIdx}
                isSelected={selectedCommentIdx === list.cmtSeq ? true : false}
                openComplain={openComplain}
                isComplained={cpCmtSeq === list.cmtSeq ? true : false}                
              />
            ))}
        {!tablet &&
          !foldState &&
          data.map((list, i) => (
            <CommentTemplate
              cmtSeq={list.cmtSeq}
              username={list.nickName}
              date={list.regDate.slice(0, 10)}
              text={list.comment}
              border={false}
              bgurl={list.thumbUrl}
              key={i}
              mSeq={list.mSeq}
              setSelectedCommentIdx={setSelectedCommentIdx}
              isSelected={selectedCommentIdx === list.cmtSeq ? true : false}
              openComplain={openComplain}
              isComplained={cpCmtSeq === list.cmtSeq ? true : false}              
            />
          ))}
        {tablet && data.length > 0 && foldState && (
          <CommentTemplate
            cmtSeq={data[0]?.cmtSeq}
            username={data[0]?.nickName}
            date={data[0]?.regDate.slice(0, 10)}
            text={data[0]?.comment}
            bgurl={data[0]?.thumbUrl}
            mSeq={data[0]?.mSeq}
            border={tablet ? true : false}
            setSelectedCommentIdx={setSelectedCommentIdx}
            isSelected={selectedCommentIdx === data[0].cmtSeq ? true : false}
            openComplain={openComplain}
            isComplained={cpCmtSeq === data[0].cmtSeq ? true : false}             
          />
        )}
        {tablet &&
          !foldState &&
          data.map((list, i) => (
            <CommentTemplate
              cmtSeq={list.cmtSeq}
              username={list.nickName}
              date={list.regDate.slice(0, 10)}
              text={list.comment}
              border={false}
              bgurl={list.thumbUrl}
              key={i}
              mSeq={list.mSeq}
              setSelectedCommentIdx={setSelectedCommentIdx}
              isSelected={selectedCommentIdx === list.cmtSeq ? true : false}
              openComplain={openComplain}         
              isComplained={cpCmtSeq === list.cmtSeq ? true : false}                
            />
          ))}
      </CommentList>
    </Wrap>
  );
}

const getValue = (location, key) => {
  let value = new URLSearchParams(location.search).get(key);

  if (!value) {
    value = "";
  }

  return value.trim();
};
