import Layout from "../components/Layout";
import S from "./Login.styled";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useRef } from "react";
import {
  checkDuplicateId,
  checkLogin,
  getMemberIdByName,
  getUserTypeSelect,
  registerMember,
  sendFindPwdMail,
  sendMberAuthCodeConfirmMail,
} from "../core/api";
import { setLoginCookie } from "../core/cookie";
import { setTempSocialKeepLogin } from "../core/localStorage";
import SelectBox from "../components/SelectBox";
import { useQuery } from "@tanstack/react-query";

export default function Login() {
  const location = useLocation();
  const pageInfo = { ...location.state };
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setError,
    reset,
    getValues,
    setValue,
  } = useForm();

  const [mode, setMode] = useState("login");
  const [defaultEmail, setDefaultEmail] = useState("");
  let saveIdValue = localStorage.getItem("SAVE_EMAIL");

  // 가입유형 가져오기
  const userTypeInfo = useQuery(["getUserTypeSelect"], () => getUserTypeSelect());
  const userTypeData = userTypeInfo.data?.rows;
  
  const [userTypeArray, setUserTypeArray] = useState([]);
  useEffect(() => {
    //SelectBox 데이터
    if( userTypeInfo.data ) {
      const _userTypeArray = userTypeData?.map((type) => {
        return Object.values(type)[1];
      });
      setUserTypeArray(_userTypeArray);
    }
  }, [userTypeInfo.data]);

  useEffect(() => {
    if (pageInfo.mode !== undefined) {
      setMode(pageInfo.mode);
    } else {
      setMode("login");
    }
  }, [location]);

  useEffect(() => {
    if (saveIdValue != null) {
      setDefaultEmail(saveIdValue);
      setValue("email", saveIdValue);
    }
  }, [defaultEmail]);


  const resendConfirmMail = async (form) => {
    let formdata = new FormData();
    let email = document.querySelector("[name=email]").value;
    formdata.append("userId", email);

    const result = await sendMberAuthCodeConfirmMail(formdata);

    if (result) {
      const returnInt = result.returnInt;
      if (returnInt == 1) {
        alert("회원인증메일이 발송되었습니다.");
      } else {
        alert("이메일 또는 이름이 존재하지 않습니다.");
      }
    }
  };


  const onValid = async ({ email, password, savingId, keepLogin }) => {
    const user = {
      email,
      password,
      savingId,
      keepLogin,
    };

    // console.log("user", user);
    let formdata = new FormData();
    formdata.append("userId", email);
    formdata.append("userPw", password);

    const result = await checkLogin(formdata);
    if (result) {
      const returnInt = result.returnInt;
      // console.log("로그인", result);

      if (returnInt == 1) {
        const accessToken = result.row.accessToken;
        const refreshToken = result.row.refreshToken;
        setLoginCookie(keepLogin, accessToken, refreshToken);
        if (!savingId) {
          localStorage.removeItem("SAVE_EMAIL");
        } else {
          localStorage.setItem("SAVE_EMAIL", email);
          // localStorage.setItem("IS_SAVE_EMAIL", true);
        }

        navigate("/");
      } else
        if(returnInt === -7){
          setError("loginError", {
            message: "이메일로 발송된 회원인증메일을 확인해 주세요.",
          });

          setTimeout(function(){
            if(window.confirm("이메일을 다시 발송하시겠습니까?")){
              navigate("/SignUpCompleted", {state:{userId:email}});
            }
          },1000);
          
        }else{
          setError("loginError", {
            message: "이메일, 비밀번호를 다시 확인해주세요.",
          });
        }
    }
  };
  const [findUserName, setFindUserName] = useState("");
  const [findUserId, setFindUserId] = useState([]);
  const onValid_email = async (form) => {
    // console.log("이메일찾기", form);

    let formdata = new FormData();
    const onlyNum = form.phoneNum.split("-").join("");

    formdata.append("userName", form.userName);
    formdata.append("userPhone", onlyNum);

    const result = await getMemberIdByName(formdata);
    if (result) {
      // console.log(
      //   "🚀 ~ file: Login.jsx:474 ~ constonValid_email= ~ result",
      //   result
      // );
      const returnInt = result.returnInt;
      if (returnInt == 1) {
        let id_list = [];
        result.row.userIdList.forEach((id) => {
          const userId_front = id.split("@")[0];
          const secreatDomain = id.split("@")[1];

          // 이메일 노출제한 **사용
          let secreatId = userId_front.substring(0, 4);
          for (let i = 0; i < userId_front.length - 4; i++) {
            secreatId += "*";
          }
          secreatId += "@" + secreatDomain;
          id_list.push(secreatId);

          // id_list.push(id);
        });
        setMode("findEmail_onValid");
        setFindUserName(form.userName);
        setFindUserId(id_list);
      } else {
        setError("phoneNum", {
          message: "입력하신 정보와 일치하는 이메일이 존재하지 않습니다.",
        });
      }
    }
  };
  const [findPwUserId, setFindPwUserId] = useState("");
  const onValid_password = async (form) => {
    // console.log("비밀번호찾기", form);

    let formdata = new FormData();

    formdata.append("userId", form.userEmail);
    // formdata.append("userName", form.username);

    const result = await sendFindPwdMail(formdata);
    // console.log(result);
    if (result) {
      const returnInt = result.returnInt;
      if (returnInt == 1) {
        setMode("findPassword_onVaild");
        setFindPwUserId(form.userEmail);
      } else {
        setError("userEmail", {
          message: "이메일 또는 이름이 존재하지 않습니다.",
        });
      }
    }
  };
  const complete_findPassword = () => {
    // console.log("비밀번호변경");
    setMode("login");
  };
  const changeValue = (type) => {
    setValue("userType", type);
    // console.log("changeValue", type);
  };
  const onValid_signUp = async (form) => {
    // console.log("회원가입", form);

    // const user = {
    //   form.userId,
    //   form.password,
    //   form.nickName,
    //   form.userName,
    //   form.phoneNum,
    //   form.userTypeCode
    // };

    var formdata = new FormData();
    // const onlyNum = form.phoneNum.split('-');

    if (!form.userType) {
      setError("userType", {
        type: "custom",
        message: "나의 직업을 선택해 주세요.",
      });
      return;
    }
    
    let typeCode;
    // switch (form.userType) {
    //   case "학생":
    //     typeCode = "S";
    //     break;
    //   case "선생님":
    //     typeCode = "T";
    //     break;
    //   case "기업":
    //     typeCode = "C";
    //     break;
    //   case "일반":
    //     typeCode = "I";
    //     break;
    //   default:
    //     typeCode = "P";
    // }

    const userTypeAryData = userTypeData.find((type) => {
      if( type.name === form.userType) return true;
    });

    typeCode = userTypeAryData.code;
    


    formdata.append("userId", form.userId);
    formdata.append("userPw", form.password);
    formdata.append("userName", form.userName);
    formdata.append("nickName", form.nickName);
    formdata.append("userPhone", form.phoneNum);
    formdata.append("userTypeCode", typeCode);

    const result = await registerMember(formdata);
    if (result) {
      const returnInt = result.returnInt;
      // console.log(result);
      if (returnInt == 1) {
        // alert("환영합니다.^^");
        // setMode("login");
        const resultEmail = await sendMberAuthCodeConfirmMail(formdata);
        if (resultEmail) {
          const returnInt = resultEmail.returnInt;
          if (returnInt == 1) {
            navigate("/SignUpCompleted", {state:{userId:form.userId}});
          } else {
            // alert("이메일 또는 이름이 존재하지 않습니다.");
            navigate("/login");
          }
        }
      } else if (returnInt == -2) {
        return setError("nickName", { message: "중복된 닉네임입니다." });
      } else if (returnInt == -1) {
        return setError("userId", { message: "중복된 이메일입니다." });
      }
    }
  };
  const [inputEmail, setInputEmail] = useState("");
  const changeEmail = (e) => {
    setInputEmail(e.target.value);
  };
  const idColor = useRef();
  const checkId = async () => {
    // console.log(inputEmail);

    if (inputEmail == "")
      return setError("userId", { message: "이메일을 입력하세요." });

    let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

    if (exptext.test(inputEmail) == false) {
      //이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우

      return setError("userId", { message: "이메일형식이 올바르지 않습니다." });
    } else {
      let formdata = new FormData();
      formdata.append("userId", inputEmail);

      const result = await checkDuplicateId(formdata);
      if (result) {
        // console.log("중복체크", result);
        setError("userId", { message: result.resultMsg });
        if (result.returnInt == 1) {
          idColor.current.classList.add("gray");
          //secondInput.current.focus();
        } else {
          idColor.current.classList.remove("gray");
          //firstInput.current.focus();
        }
      }
    }
  };
  const showFindEmail = () => {
    setMode("findEmail");
  };
  const showSignUp = () => {
    setMode("signUp");
  };
  const firstInput = useRef();
  const secondInput = useRef();
  useEffect(() => {
    reset({
      email: "",
      password: "",
      username: "",
      phoneNum: "",
      userId: "",
    });
    setInputValue("");
    setInputEmail("");
    clearErrors();
    window.scrollTo(0, 0);
    //if (firstInput.current !== null) firstInput.current.focus();
  }, [mode]);
  const closeFindEmail = () => {
    setMode("login");
  };
  const cancelSignUp = (e) => {
    e.preventDefault();
    setMode("login");
    // console.log("cancelSignUp");
  };
  const [inputValue, setInputValue] = useState("");
  const phoneNumHandleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };
  useEffect(() => {
    if (inputValue.length === 10) {
      setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (inputValue.length === 13) {
      setInputValue(
        inputValue
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [inputValue]);

  // const onWebLink = (obj) => {
  //   const keepLogin = getValues("keepLogin");
  //   keepLogin && setTempSocialKeepLogin();

  //   const webLink = obj.target.getAttribute("data-web-link");
  //   window.open(webLink, "_self");
  // };
  const onWebLink = (url) => {
    const keepLogin = getValues("keepLogin");
    keepLogin && setTempSocialKeepLogin();

    window.open(url, "_blank");
  };

  return (
    <Layout>
      <S.Wrapper>
        {mode === "login" ? (
          <S.Inner>
            <h2>로그인</h2>
            <S.Form onSubmit={handleSubmit(onValid)}>
              <label htmlFor="email">이메일</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("email", {
                  required: {
                    value: true,
                    message: "이메일을 입력하세요.",
                  },
                })}
                type="email"
                placeholder="이메일을 입력하세요."
                defaultValue={defaultEmail}
                //ref={firstInput}
              />
              <p></p>
              <label htmlFor="userPassword">비밀번호</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("password", {
                  required: {
                    value: true,
                    message: "비밀번호를 입력해 주세요.",
                  },
                  minLength: {
                    value: 8,
                    message: "비밀번호는 최소 8글자 이상이어야 합니다.",
                  },
                  maxLength: {
                    value: 16,
                    message: "비밀번호는 최대 16글자 이하이어야 합니다.",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@$%#&+^*=/~`()<>?,.;:'"_-])[A-Za-z0-9!@$%#&+^*=/~`()<>?,.;:'"_-]{8,16}$/,
                    message:
                      "비밀번호는 숫자, 영문, 특수문자를 포함한 8글자 이상이어야 합니다.",
                  },
                })}
                type="password"
                placeholder="영문, 숫자 및 특수 기호를 포함하여 8자 이상 입력하십시오."
                autoComplete="off"
              />
              <p>
                {errors?.email ? (
                  <em>{errors.email.message}</em>
                ) : errors?.password ? (
                  <em>{errors.password.message}</em>
                ) : (
                  <em>{errors?.loginError?.message}</em>
                )}
              </p>
              <div className="checkBox">
                <input
                  {...register("savingId")}
                  type="checkbox"
                  id="check1"
                  defaultChecked={true}
                />
                <S.CheckBoxLabel htmlFor="check1">이메일 저장</S.CheckBoxLabel>{" "}
                <input {...register("keepLogin")} type="checkbox" id="check2" />
                <S.CheckBoxLabel htmlFor="check2">로그인 유지</S.CheckBoxLabel>
              </div>
              <button>로그인</button>
            </S.Form>
            <div>
              <span onClick={showFindEmail}>이메일 찾기</span> |{" "}
              <span
                onClick={() => {
                  setMode("findPassword");
                }}
              >
                비밀번호 찾기
              </span>{" "}
              | <span onClick={showSignUp}>회원가입</span>
            </div>
            <p>기존에 사용하시는 계정으로 간단하게 시작하세요.</p>
            <div className="btnBox">
              <span
                className="naverBtn"
                onClick={() =>
                  onWebLink("https://admin.kpub.co.kr/social_login/naver")
                }
              ></span>
              <span
                className="katalkBtn"
                onClick={() =>
                  onWebLink("https://admin.kpub.co.kr/social_login/kakao")
                }
              ></span>
              <span
                className="googleBtn"
                onClick={() =>
                  onWebLink("https://admin.kpub.co.kr/social_login/google")
                }
              ></span>
            </div>
          </S.Inner>
        ) : null}
        {mode === "findEmail" ? (
          <S.InnerFind>
            <S.CloseBtn
              onClick={() => {
                setMode("login");
              }}
            ></S.CloseBtn>
            <h2>이메일 찾기</h2>
            <S.FormFindId
              onSubmit={handleSubmit(onValid_email)}
              mode={mode ? mode : null}
            >
              <label htmlFor="userName">이름</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("userName", {
                  required: {
                    value: true,
                    message: "이름을 입력하세요.",
                  },
                  maxLength: 16,
                })}
                id="userName"
                type="text"
                placeholder="이름을 입력하세요."
                //ref={firstInput}
              />
              <p>
                {errors?.userName ? <em>{errors.userName.message}</em> : null}
              </p>
              <label htmlFor="phoneNum">전화번호</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("phoneNum", {
                  required: {
                    value: true,
                    message: "유효한 전화번호를 입력하세요.",
                  },
                })}
                id="phoneNum"
                type="text"
                placeholder="하이픈(-) 없이 전화번호를 입력하세요."
                onChange={phoneNumHandleChange}
                value={inputValue}
              />
              <p className="gray-500">
                {errors?.phoneNum ? (
                  <em>{errors.phoneNum.message}</em>
                ) : ("")}
              </p>
              {/* <button type="button" className="phoneAuth pretendardSB">
                전화 인증
              </button>
              <p>
                {errors?.phoneNum ? <em>{errors.phoneNum.message}</em> : null}
              </p>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("phoneCode", {
                  required: {
                    value: true,
                    message: "인증코드를 입력해주세요.",
                  },
                })}
                id="phoneCode"
                type="text"
                placeholder="인증코드"
              />
              <p className="gray-500">
                {errors?.phoneCode ? (
                  <em>{errors.phoneCode.message}</em>
                ) : (
                  "인증코드를 입력하신 전화번호로 전송하였습니다."
                )}
              </p> */}
              {/*<div className="absolBox">
                <div className="timer">05:00</div>
                <button
                  type="button"
                  className="phoneAuth pretendardSB confirm"
                >
                  확인
                </button> 
              </div>*/}
              <button type="submit">확인</button>
            </S.FormFindId>
            <div>
              <p>
                이메일 및 비밀번호 찾기에 문제가 있으신 분들은 아래 이메일로
                연락 부탁드립니다.
              </p>
              <span className="email">help@tovsoft.com</span>
            </div>
          </S.InnerFind>
        ) : null}
        {mode === "findPassword" ? (
          <S.InnerFind>
            <S.CloseBtn
              onClick={() => {
                setMode("login");
              }}
            ></S.CloseBtn>
            <h2>비밀번호 찾기</h2>
            <S.FormFindId
              onSubmit={handleSubmit(onValid_password)}
              mode={mode ? mode : null}
            >
              <label htmlFor="userEmail">이메일</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("userEmail", {
                  required: {
                    value: true,
                    message: "이메일을 입력하세요.",
                  },
                })}
                id="userEmail"
                type="email"
                placeholder="회원가입시 등록한 이메일을 입력하세요."
                //ref={firstInput}
              />
               <p>
                {errors?.userEmail ? <em>{errors.userEmail.message}</em> : null}
              </p>
              {/*
              <label htmlFor="username">이름</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("username", {
                  required: {
                    value: true,
                    message: "이름을 입력하세요.",
                  },
                })}
                id="username"
                type="text"
                placeholder="이름을 입력하세요."
              />
              <p>
                {errors?.username ? <em>{errors.username.message}</em> : null}
              </p>
              <label htmlFor="phoneNum">전화번호</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("phoneNum", {
                  required: {
                    value: true,
                    message: "유효한 전화번호를 입력하세요.",
                  },
                  minLength: {
                    value: 10,
                    message: "유효한 전화번호를 입력하세요.",
                  },
                })}
                id="phoneNum"
                type="text"
                placeholder="하이픈(-) 없이 전화번호를 입력하세요."
                onChange={phoneNumHandleChange}
                value={inputValue}
              />
              <button type="button" className="phoneAuth pretendardSB">
                전화 인증
              </button>
              <p>
                {errors?.phoneNum ? <em>{errors.phoneNum.message}</em> : null}
              </p>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("phoneCode", {
                  required: {
                    value: true,
                    // message: "인증코드를 입력해주세요.",
                  },
                })}
                id="phoneCode"
                type="text"
                placeholder="인증코드"
              />
              <p className="gray-500">
                {errors?.phoneCode ? (
                  <em>{errors.phoneCode.message}</em>
                ) : (
                  "인증코드를 입력하신 전화번호로 전송하였습니다."
                )}
              </p> 
              <div className="absolBox">
                <div className="timer">05:00</div>
                <button
                  type="button"
                  className="phoneAuth pretendardSB confirm"
                >
                  확인
                </button>
              </div>
              */}
              <button type="submit">비밀번호 재설정 링크 발송하기</button>
            </S.FormFindId>
            <div>
              <p>
                이메일 및 비밀번호 찾기에 문제가 있으신 분들은 아래 이메일로
                연락 부탁드립니다.
              </p>
              <span>help@tovsoft.com</span>
            </div>
          </S.InnerFind>
        ) : null}
        {mode === "findEmail_onValid" ? (
          <S.InnerFind>
            <S.CloseBtn
              onClick={() => {
                setMode("login");
              }}
            ></S.CloseBtn>
            <h2>이메일 찾기</h2>
            <div className="accountTxt">
              <div>{`${findUserName} 회원님의 이메일 입니다.`}</div>
              <div className="flexRow">
                <div>개인계정: </div>
                <div className="flexCol">
                  {findUserId.map((id, i) => (
                    <div key={i}>{id}</div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flexBtn">
              <button
                onClick={() => {
                  setMode("login");
                }}
              >
                로그인
              </button>
              <button
                onClick={() => {
                  setMode("findPassword");
                }}
              >
                비밀번호 찾기
              </button>
            </div>
            <div>
              <p>
                이메일 및 비밀번호 찾기에 문제가 있으신 분들은 아래 이메일로
                연락 부탁드립니다.
              </p>
              <span className="email">help@tovsoft.com</span>
            </div>
          </S.InnerFind>
        ) : null}
        {mode === "findPassword_onVaild" ? (
          <S.InnerFind>
            <S.CloseBtn
              onClick={() => {
                setMode("login");
              }}
            ></S.CloseBtn>
            <h2>이메일 발송 완료</h2>
            <S.FormFindId onClick={complete_findPassword}>
              <div>임시 비밀번호가 사용자의 이메일로 전송되었습니다.</div>
              <div className="findPwEmail">{`${findPwUserId}`}</div>
              <button>로그인</button>
            </S.FormFindId>
            <div>
              <p>
                이메일 및 비밀번호 찾기에 문제가 있으신 분들은 아래 이메일로
                연락 부탁드립니다.
              </p>
              <span>help@tovsoft.com</span>
            </div>
          </S.InnerFind>
        ) : null}
        {mode === "signUp" ? (
          <S.InnerFind>
            <S.CloseBtn
              onClick={() => {
                setMode("login");
              }}
            ></S.CloseBtn>
            <h2>회원가입</h2>
            <S.FormSignUp onSubmit={handleSubmit(onValid_signUp)}>
              <label htmlFor="userId">이메일</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("userId", {
                  required: {
                    value: true,
                    message: "이메일을 입력하세요.",
                  },
                  maxLength: {
                    value: 30,
                    message: "이메일은 최대 30글자 이하입니다.",
                  },
                })}
                type="email"
                placeholder="이메일을 입력하세요."
                onChange={changeEmail}
                value={inputEmail}
                //ref={firstInput}
              />
              <button
                type="button"
                className="checkDuplicateId pretendardSB phoneAuth"
                onClick={checkId}
              >
                중복 확인
              </button>
              <p ref={idColor}>
                {errors?.userId ? <em>{errors.userId.message}</em> : null}
              </p>

              <label htmlFor="nickName">닉네임</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("nickName", {
                  required: {
                    value: true,
                    message: "닉네임을 입력하세요.",
                  },
                  maxLength: {
                    value: 10,
                    message: "닉네임은 최대 10글자 이하입니다.",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9가-힣]{2,10}$/,
                    message:
                      "닉네임은 공백과 특수문자를 제외한 2~10글자 입니다.",
                  },
                })}
                type="text"
                placeholder="닉네임을 입력하세요."
                //ref={secondInput}
              />
              <p>
                {errors?.nickName ? <em>{errors.nickName.message}</em> : null}
              </p>

              <label htmlFor="password">비밀번호</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("password", {
                  required: {
                    value: true,
                    message: "비밀번호를 입력해 주세요.",
                  },
                  minLength: {
                    value: 8,
                    message: "비밀번호는 최소 8글자 이상이어야 합니다.",
                  },
                  maxLength: {
                    value: 16,
                    message: "비밀번호는 최대 16글자 이하이어야 합니다.",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@$%#&+^*=/~`()<>?,.;:'"_-])[A-Za-z\d!@$%#&+^*=/~`()<>?,.;:'"_-]{8,16}$/,
                    message:
                      "비밀번호는 숫자, 영문, 특수문자를 포함한 8글자 이상이어야 합니다.",
                  },
                })}
                type="password"
                placeholder="영문, 숫자, 특수기호 포함 8글자 이상"
                autoComplete="off"
              />
              <p>
                {errors?.password ? <em>{errors.password.message}</em> : null}
              </p>

              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("passwordCheck", {
                  required: {
                    value: true,
                    message: "비밀번호를 확인해주세요.",
                  },
                })}
                type="password"
                placeholder="비밀번호 확인"
                autoComplete="off"
              />
              <p>
                {errors?.passwordCheck ? (
                  <em>{errors.passwordCheck.message}</em>
                ) : null}
              </p>

              <label htmlFor="userName">이름</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("userName", {
                  required: {
                    value: true,
                    message: "이름을 입력하세요.",
                  },
                  maxLength: {
                    value: 12,
                    message: "이름은 최대 12글자 이하이어야 합니다.",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9가-힣]{2,20}$/,
                    message: "이름은 공백과 특수문자를 제외한 2~20글자 입니다.",
                  },
                })}
                type="text"
                placeholder="이름을 입력하세요."
              />
              <p>
                {errors?.userName ? <em>{errors.userName.message}</em> : null}
              </p>

              <label htmlFor="phoneNum">전화번호</label>
              <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("phoneNum", {
                  required: {
                    value: true,
                    message: "유효한 전화번호를 입력하세요.",
                  },
                })}
                id="phoneNum"
                type="text"
                placeholder="하이픈(-) 없이 전화번호를 입력하세요."
                onChange={phoneNumHandleChange}
                value={inputValue}
              />
              {/* <button type="button" className="phoneAuth pretendardSB">
                전화 인증
              </button> */}
              <p>
                {errors?.phoneNum ? <em>{errors.phoneNum.message}</em> : null}
              </p>
              {/* <input
                onInput={() => {
                  clearErrors();
                }}
                {...register("phoneCode", {
                  required: {
                    value: true,
                    message: "인증코드를 입력해주세요.",
                  },
                })}
                id="phoneCode"
                type="text"
                placeholder="인증코드"
              />
              <p className="gray-500">
                {errors?.phoneCode ? (
                  <em>{errors.phoneCode.message}</em>
                ) : (
                  "인증코드를 입력하신 전화번호로 전송하였습니다."
                )}
              </p>
              <div className="absolBox">
                <div className="timer">05:00</div>
                <button type="button" className="phoneAuth pretendardSB confirm">
                  확인
                </button>
              </div> */}

              {/* <div className="userType">가입 유형</div> */}
              <div className="userTypeList">
                <SelectBox
                  array={userTypeArray}
                  register={register("userType")}
                  value="나의 직업"
                  changeValue={changeValue}
                />
                <p
                  className="gray-500"
                  style={{
                    fontSize: "12px",
                    height: "24px",
                    paddingLeft: "8px",
                  }}
                >
                  {errors?.userType ? <em>{errors.userType.message}</em> : null}
                </p>
              </div>

              {/* <div className="terms">이용 약관 동의</div>
              <span className="termsBtn">약관 보기</span> */}

              <div className="buttonContainer">
                <button
                  type="button"
                  className="cancelBtn"
                  onClick={cancelSignUp}
                >
                  취소
                </button>
                <button type="submit">확인</button>
              </div>
            </S.FormSignUp>
            <div>
              <p>
                이메일 및 비밀번호 찾기에 문제가 있으신 분들은 아래 이메일로
                연락 부탁드립니다.
              </p>
              <span>help@tovsoft.com</span>
            </div>
          </S.InnerFind>
        ) : null}
      </S.Wrapper>
    </Layout>
  );
}
