import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Container from "../components/Container";
import ThumbGrid from "../components/ThumbGrid";
import Subfilter from "../components/Subfilter";
import EbookContainer from "../components/EbookContainer";
import { sortingByPram } from "../util";
import Hashtag from "../components/Hashtag";

export default function MoreEbooks() {
  const location = useLocation();
  const searchedData = location.state.data;
  const [filter, setFilter] = useState(false);
  const [sortedData, setSortedData] = useState(location.state.data);

  // const array = location.state.data;
  // array.sort((a, b) => {
  //   return a.id - b.id;
  // });
  useEffect(() => {
    setFilter(location.state.needFilter);
    // console.log("location.state", location.state.description);
  }, []);

  const getParam = (opt) => {
    const array = sortingByPram(location.state.data, opt);
    setSortedData(array);
  };

  return (
    <Layout>
      <Hashtag />
      <PageTitle
        title={`${location.state.title}`}
        kind="moreEbooks"
        description={`${location.state.description}`}
      />
      <Container>
        <Subfilter filter={filter} getParam={getParam} />
        <EbookContainer>
          <ThumbGrid data={sortedData} />
        </EbookContainer>
      </Container>
    </Layout>
  );
}
