import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Hashtag from '../components/Hashtag'
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendMberAuthCodeConfirmMail } from "../core/api";

const StyledScreen = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 635px;
  min-width: 150px;
  position: relative;
  margin: 80px auto 80px;
  padding: 0px 10px;

  & .frame {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  & .text {
    align-self: stretch;
    color: #212b36;
    font-family: "Pretendard-Bold", Helvetica;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }

  & .label-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  & .label {
    color: #212b36;
    flex: 1;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }

  & .view {
    align-items: center;
    align-self: stretch;
    background-color: #f7f7f7;
    border-radius: 12px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
    width: 100%;
  }

  & .div-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
    width: 100%;
  }

  & .text-wrapper {
    color: #000000;
    flex: 1;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    opacity: 0.5;
    position: relative;
  }

  & .div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  & .button {
    all: unset;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #d9ddea;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    height: 49px;
    justify-content: center;
    max-width: 202px;
    min-width: 150px;
    padding: 4px 0px;
    position: relative;
    cursor: pointer;
  }

  & .text-2 {
    color: #393939;
    font-family: "Pretendard-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  & .buttons-primary {
    align-items: center;
    background-color: #f74551;
    border-radius: 6px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    justify-content: center;
    max-width: 472px;
    min-width: 150px;
    padding: 14px 0px;
    position: relative;
    height:60px;
    border:none;
    cursor: pointer;
  }

  & .label-2 {
    color: #ffffff;
    font-family: "Pretendard-Regular", Helvetica;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 244.14px;
  }
`;

export default function SignUpCompleted() {
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const userId = location.state? location.state.userId : '';

  useEffect(() => {

    if(userId === ''){
      navigate("/login");
    }
    
  }, [])

  const goHome = () => {
    navigate("/");
  };

  const resendConfirmMail = async () => {
    let formdata = new FormData();
    formdata.append("userId", userId);

    const result = await sendMberAuthCodeConfirmMail(formdata);

    if (result) {
      const returnInt = result.returnInt;
      if (returnInt == 1) {
        alert("회원인증메일이 발송되었습니다.");
      } else {
        alert("이메일 또는 이름이 존재하지 않습니다.");
      }
    }
  };

  
  return (
    <Layout>
    <Hashtag />
    <StyledScreen>
      <div className="frame">
        <div className="text">인증메일이 발송되었습니다.</div>
      </div>
      <div className="label-wrapper">
        <p className="label">
          메일함에서(help@tovsoft.com) 인증 메일을 확인바랍니다.
          <br />
          이메일 인증 버튼을 선택하면 회원가입이 완료됩니다.
        </p>
      </div>
      <div className="view">
        <div className="div-wrapper">
          <p className="text-wrapper">
            인증 메일은 재발송 시 기존 인증 코드는 만료됩니다. 반드시 마지막에 수신된 메일을 확인바랍니다.
            <br />
            메일이 도착하지 않았다면 스팸함을 확인해 주시기 바랍니다.
          </p>
        </div>
        <div className="div">
          <button className="button" onClick={resendConfirmMail}>
            <div className="text-2">인증메일 재발송</div>
          </button>
        </div>
      </div>
      <div className="div">
        <button className="buttons-primary" onClick={goHome}>
          <div className="label-2">홈으로 돌아가기</div>
        </button>
      </div>
    </StyledScreen>
    </Layout>
  )
}
