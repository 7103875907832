import { useEffect, useRef } from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { getThumbnailColor, useUser } from "../util";
import { mberInfoAtom } from "../atoms";
import { useRecoilValue } from "recoil";
import { deleteEbookComment, updateEbookComment } from "../core/api";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.$isComplained ? "#e3e3e3" : "none"}; 
  border: ${(props) => props.$isComplained ? "1px solid red" : "none"}; 
  
  .desktop.commentZone {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .desktop.commentZone textarea {
    width: 95%;
  }
`;

const CommentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  padding-left: 0px;
  border-bottom: ${(props) => (props.border ? `1px solid ${props.theme.gray300}` : "none")};
  padding-bottom: ${(props) => (props.ismobile ? "30px" : "0px")};
  svg.dots {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 2%;
    top: 28%;
    cursor: pointer;
  }
`;
const ProfileImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-image: url(${(props) => props.bgurl});
  background-color: ${(props) => (props.bgurl ? "none" : props.bgColor)};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-right: 10px;
`;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 40px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  strong {
    font-size: 14px;
    font-family: "Pretendard-Bold";
    color: ${(props) => props.theme.gray600};
    @media ${({ theme }) => theme.device.mobileLg} {
      font-size: 12px;
    }
  }
  span {
    font-size: 14px;
    color: ${(props) => props.theme.gray500};
    @media ${({ theme }) => theme.device.mobileLg} {
      font-size: 12px;
    }
  }
`;
const Text = styled.p`
  font-size: 17px;
  font-family: "Pretendard-Light";
  color: ${(props) => props.theme.gray800};
  white-space: pre-wrap;
  line-height: 1.5;
  @media ${({ theme }) => theme.device.mobileLg} {
    font-size: 15px;
  }
`;
const WideText = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.gray600};
  @media ${({ theme }) => theme.device.mobileLg} {
    font-size: 16px;
  }
`;
const PopMenu = styled.div`
    display: none;
    flex-direction: column;
    position: absolute;
    top: 15px;
    right: 19px;
    width: max-content;
    padding: 0;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(168, 164, 164);
    border-radius: 4px;
    z-index: 2;
    ul {
      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
      padding: 5px 15px;
      li {
        padding: 3px 0;
        font-size: 18px;
        &.off {
          opacity: 0.3;
          pointer-events: none;
        }
        &:hover {
          color: #f74551;
        }
        a {
          display: inline-block;
          width: 100%;
          font-size: 14px;
          letter-spacing: 3px;
        }
        span {
          cursor: pointer;
        }
      }
    }
`;



const Comment = ({ cmtSeq, username, date, text, bgurl, border, mSeq, setSelectedCommentIdx, isSelected, openComplain, isComplained}) => {
  const mobile = useMediaQuery({ query: `(max-width: 480px)` });
  const tablet = useMediaQuery({ query: `(max-width: 767px)` });
  const [bgColor, setBgColor] = useState("");

  const mberInfo = useRecoilValue(mberInfoAtom);
  const userMseq= mberInfo.mSeq;
  const { user } = useUser();
  
  // console.log("isComplained", isComplained, cmtSeq);
  
  useEffect(() => {
    setBgColor(getThumbnailColor(mSeq));
  }, [mSeq]);

  // 팝업 메뉴
  const btnPopClick = (e) => {
    e.stopPropagation();
    document.querySelectorAll(".popMenu").forEach((el) => {
      el.style.display = "none";
    });
    const popMenu = e.currentTarget.nextSibling;
    popMenu.style.display = (popMenu.style.display === "none" || popMenu.style.display === "") ? "flex" : "none";
  }

  // 영역외 클릭시 팝업 메뉴 닫기
  const outsideClick = (e) => { 
      if (!e.target.closest(".dots")) {
        document.querySelectorAll(".popMenu").forEach((el) => {
          el.style.display = "none";
        });
      }

      if (!e.target.closest(".commentWrapper")) {
        setSelectedCommentIdx(0);
      }
  }

  const [userComment, setUserComment] = useState("");
  const btnOpenComment = async (e) => {
    e.preventDefault();
    // e.target.closest(".commentWrapper").querySelector(".commentZone").style.display = "block";
    setSelectedCommentIdx(cmtSeq);
  };
  const btnUpdateComment = async (e) => {
    let formdata = new FormData();
    const comment = e.target.closest('.commentWrapper').querySelector('.commentZone textarea').value;
    
    formdata.append("cmtSeq", cmtSeq);
    formdata.append("comment", comment);

    const result = await updateEbookComment(formdata);
    const returnInt = result.returnInt;
    if (returnInt === 1) {
      alert("댓글이 수정되었습니다.");
      window.location.reload();
    } else { 
      alert("일시적인 오류가 발생하였습니다. 잠시후 다시 시도해주세요.");
    }
  }
  const textarea = useRef();
  const handleResizeHeight = () => {
    textarea.current.style.height = "auto";
    textarea.current.style.height = textarea.current.scrollHeight + "px";
  };

  const btnDeleteComment = async (e) => {
    
    if(!window.confirm("정말 삭제하시겠습니까?")) return;
    
    let formdata = new FormData();
    formdata.append("cmtSeq", cmtSeq);

    const result = await deleteEbookComment(formdata);
    const returnInt = result.returnInt;
    if (returnInt === 1) {
      alert("댓글이 삭제되었습니다.");
      window.location.reload();
    } else { 
      alert("일시적인 오류가 발생하였습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const btnOpenComplain = (e) => {
    e.preventDefault();
    const cmtInfo = {
      cmtTf: 1,
      cmtSeq: cmtSeq
    }
    openComplain(e, cmtInfo);
  }

  useEffect(() => {
    document.addEventListener("click", (e) => outsideClick(e));
    return () => {
      document.removeEventListener("click", (e) => outsideClick(e));
    };
  }, []);

  return (
    <>
      <Wrapper className="commentWrapper" $isComplained={isComplained}>
        <CommentContainer border={border} ismobile={tablet ? true : false}>
          <ProfileImg bgurl={bgurl} bgColor={bgColor}>
            {bgurl ? "" : username.charAt(0)}
          </ProfileImg>
          <FlexCol>
            <FlexRow>
              <strong>{username}</strong>
              <span>{date}</span>
            </FlexRow>
            <Text abs={mobile ? true : false}>{text}</Text>
          </FlexCol>
          {user && (
            <>
              <svg
                className="dots"
                width="5"
                height="24"
                viewBox="0 0 5 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={btnPopClick}
                style={{right:0}}
              >
                <path
                  d="M2.73346 5.19959C1.48162 5.19959 0.466797 4.18477 0.466797 2.93293C0.466797 1.68108 1.48162 0.66626 2.73346 0.66626C3.98531 0.66626 5.00013 1.68108 5.00013 2.93293C5.00013 4.18477 3.98531 5.19959 2.73346 5.19959Z"
                  fill="#777373"
                />
                <path
                  d="M2.73361 14.266C1.48176 14.266 0.466943 13.2512 0.466943 11.9993C0.466943 10.7475 1.48176 9.73267 2.73361 9.73267C3.98546 9.73267 5.00028 10.7475 5.00028 11.9993C5.00028 13.2512 3.98546 14.266 2.73361 14.266Z"
                  fill="#777373"
                />
                <path
                  d="M2.73361 23.3339C1.48176 23.3339 0.466943 22.319 0.466943 21.0672C0.466943 19.8154 1.48176 18.8005 2.73361 18.8005C3.98546 18.8005 5.00028 19.8154 5.00028 21.0672C5.00028 22.319 3.98546 23.3339 2.73361 23.3339Z"
                  fill="#777373"
                />
              </svg>
            
              <PopMenu className="popMenu">
                <ul>
                  {userMseq === mSeq ?(
                    <>
                      <li><a onClick={btnOpenComment}>수정</a></li>
                      <li><a onClick={btnDeleteComment}>삭제</a></li>
                    </>
                  ): (
                    <>
                      <li><a onClick={btnOpenComplain} >신고</a></li>
                    </>
                  )}
                </ul>
              </PopMenu>
            </>
          )}
        </CommentContainer>
        { user && isSelected && (
          <div className="desktop commentZone">
            <textarea
              type="text"
              placeholder="댓글을 입력해주세요."
              ref={textarea}
              rows={1}
              onChange={(e) => {
                setUserComment(e.target.value);
                handleResizeHeight();
              }}
              defaultValue={text}
            />
            <button onClick={btnUpdateComment}>수정</button>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default Comment;
