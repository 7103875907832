import { useEffect, useRef } from "react";

const callbackInterval = 200;

export function usePressAndHold() {
  const intervalRef = useRef(null);

  const startCounter = (callback) => {
    if (intervalRef.current) {
      return;
    }

    callback();

    intervalRef.current = setInterval(() => {
      callback();
    }, callbackInterval);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    return () => stopCounter();
  }, []);

  return { startCounter, stopCounter };
}
