import { Line, getElementAtEvent } from 'react-chartjs-2';
import {
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement, 
    Title,
    Tooltip,
    Legend,
    plugins,
  } from 'chart.js';
import { useRef } from 'react';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    plugins
  );


export const StatLineChart = (props) => {
    
    // console.log('StatLineChart');

    const chartRef = useRef();

    /**
     * 차트 라벨 데이터 생성
     * 월별 누락된 데이터는 0으로 채움
     */
    let yearChartData = [];

    for(let i=0; i<12; i++){
       yearChartData.push(0);
    }

    for(let i=0; i<props.chartData?.rows.length; i++){
        yearChartData[parseInt(props.chartData?.rows[i].regMonth)-1] = props.chartData?.rows[i].cnt;
    }
    
    
    
    /***
     * 차트 데이터 설정
     */
    const lineChartData = {
        labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        datasets: [
            {
                label: '조회수', 
                data: yearChartData, // 데이터
                borderColor: ['rgba(255, 99, 132, 1)'],
                backgroundColor: ['rgba(255, 99, 132, 1)'],
                tension: 0,
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 10,
            },
        ],
    };

    let title = `${props.chartTitle} 추이   [ ${props.getYear} 년도 ]`
    if (props.getSelDate.selDate.toString() !== '0') {
      title = title + ` [ ${props.getSelDate.selDate}]`;
    }

    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                position: 'top',
                align: 'start',
                text: title,
                padding: {
                    top: 10,
                    bottom: 30
                },
                font: {
                  size: 14,
                  style: 'normal',
                  family: 'Pretendard-Medium'
                }
            }
        },
      
    };
    
    // 차트 클릭 이벤트
    const handlePointClick = (e) => {
        let selDate = '';
        if (getElementAtEvent(chartRef.current, e).length > 0) {
            const dataPoint = getElementAtEvent(chartRef.current, e)[0].index;
            // console.log(dataPoint);
            for(let i=0; i<props.chartData?.rows.length; i++){
                if( props.chartData?.rows[i].regMonth === dataPoint+1) {
                    selDate = props.chartData?.rows[i].regDate;
                    break;
                }
            }
        }

        if(selDate === ''){
            selDate = '0';
        } 
        
        // console.log(selDate);
        props.setSelDate({...props.getSelDate,selDate:selDate});
    }
    
    return (
        <Line
            options={options} 
            data={lineChartData} 
            onClick={handlePointClick} 
            ref={chartRef}
        />
    );
}