import { useCallback, useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import editBtn from "../images/components/contentsManager/editBtn.svg";
import deleteBtn from "../images/components/contentsManager/deleteBtn.svg";
import downLoadBtn from "../images/components/modal/downLoadBtn.svg";
import downLoadBtn_red from "../images/components/modal/downLoadBtn_red.svg";
import dropIcon from "../images/components/contentsManager/dropIcon.svg";
import {
  deleteBookContents,
  getUserContentsList,
  updateUserContentsPublic,
  updateUserContentsDesc,
} from "../core/api";
import Loading from "./Loading";
import { useRecoilState } from "recoil";
import Modal from "../components/Modal";
import ModalContentManager from "../components/ModalContentManager";
import { modalState, modalState2 } from "../atoms";
import { useMediaQuery } from "react-responsive";
import { sortingByPram } from "../util";
import Toggle from "react-styled-toggle";
import { TagsInput } from "react-tag-input-component";

const TagsInputStyle = styled.span`
  .rti--container {
    max-height: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    --rti-border: #f0f0f0;
    margin-bottom: 13px;
  }
`;

const Manager = styled.div`
  position: relative;
  margin-bottom: 60px;
  table {
    width: 100%;
    tr {
      border-bottom: 1px solid #c0c5d1;
      &.listTit {
        border-top: 1px solid #c0c5d1;
        background-color: #f7f8fa;
      }
      th {
        padding: 16px 0;
        font-size: 14px;
        line-height: 17px;
        color: #0f0f10;
      }
      th:first-child,
      td:first-child {
        padding-left: 20px;
      }
      /* td:last-child {
        padding-right: 20px;
      } */

      td {
        padding: 10px 4px;
        vertical-align: middle;
        font-family: "Pretendard-Regular";
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        /* outline: 1px solid; */
        div,
        button {
          display: flex;
          flex-direction: column;
          border: none;
          margin: 0 auto;
        }
        span,
        button {
          cursor: pointer;
        }
        .thumnail {
          width: 85px;
          height: 64px;
          margin-left: 0;
          background-color: pink;
          border-radius: 3px;
          overflow: hidden;
        }

        .thumnail > img {
          width: 85px;
          height: 64px;
          object-fit: cover;
          border: solid 1px #cfcfcf;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          text-align: left;
          p {
            font-family: "Pretendard-Regular";
            font-size: 13px;
            line-height: 16px;
            color: #9a9ba7;
            &:first-child {
              font-size: 15px;
              line-height: 18px;
              color: #303441;

              display: -webkit-box;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              white-space: normal;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }
        }
        .editBtn {
          width: 18px;
          height: 18px;
          background: url(${editBtn}) center/ contain no-repeat;
        }
        .deleteBtn {
          width: 18px;
          height: 20px;
          background: url(${deleteBtn}) center/ contain no-repeat;
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    overflow-x: scroll;
    /* margin-bottom: 30px; */
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: transparent;
    }
    @media ${({ theme }) => theme.device.tablet} {
      &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.gray300};
      }
    }
    table {
      min-width: 892px;
      margin-bottom: 10px;
      .jikjiName {
        pointer-events: none;
      }
      .editBtn {
        background-color: red;
      }
    }
  }
`;
const FilterBtn = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .orderingIcon {
    display: inline-block;
    width: 9px;
    height: 5px;
    margin-left: 3px;

    background: url(${dropIcon}) center/100% no-repeat;
    transform: ${(props) =>
      props.isDescending ? `rotate(0deg)` : `rotate(180deg)`};
  }
`;

const QrCode = styled.button`
  width: 30px;
  height: 30px;
  background: #f2f2f2 url(${(props) => props.qrImg}) center/ 100% auto no-repeat;
`;
const Disclosure = styled.button`
  display: flex;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 16px;
  border-radius: 18px;
  background-color: #19acfa;
  &.switchBtn:after {
    content: "";
    position: absolute;
    left: 16px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
  }
  &.switchBtn.off {
    background-color: #c0c5d1;
    &::after {
      transform: translate(-14px, 0px);
    }
  }
`;

const ModalStyled = styled(Modal)`
  &.modalWrap {
    background-color: red;
  }
`;
const PopupInner = styled.div`
  width: 192px;
  height: 298px;
  border-radius: 10px;
  overflow: hidden;
  /* h2 {
    font-family: "Pretendard-Medium";
    font-size: 22px;
    line-height: 26px;
    color: #303441;
  } */
`;
const PopupQrImg = styled.img`
  width: 160px;
  height: 160px;
  margin: 0 auto 64px;
  background: url(${(props) => props.popQrImg}) center/ 100% auto no-repeat;
`;

const PopupQrBtn = styled.button`
  &.QrDownBtn {
    bottom: 16px;

    width: 159px;
    height: 40px;

    padding: 0 0 0 48px;
    background: #ffffff url(${downLoadBtn}) 24px center/20px auto no-repeat;
    border: 1px solid #c0c5d1;
    border-radius: 6px;
    font-family: "Pretendard-SemiBold";
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #303441;
    &:hover {
      background-image: url(${downLoadBtn_red});
    }
  }
`;

const ModalCoverImg = styled.span`
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 212 / 162;
  background-image: url(${(props) => props.src});
  background-position: center top;
  background-size: cover;
  transition: transform 0.3s ease 0s;

  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 15px 0px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;

  /* width:  */
  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

const CclForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1 1 0;
  padding: 0 20px;

  & div {
    width: 100%;
  }

  & div.left {
    width: 70%;
    margin-right: 20px;
  }

  & ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px #eee;
    border-radius: 10px;
  }

  & ul > li {
    font-size: 14px;
    padding: 13px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & ul > li:first-child {
    font-weight: bold;
    font-size: 16px;
  }

  & li > div {
    width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  & li > div:first-child {
    justify-content: flex-start;
  }

  /* width:  */
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 100% !important;
    & div.left {
      width: 100%;
    }
  }
`;

export default function ContentsManager() {
  let formdata = new FormData();
  const {
    data: contentData,
    isLoading,
    refetch,
  } = useQuery(["contentsList"], getUserContentsList);
  // const content = contentData?.rows;
  const [sortedData, setSortedData] = useState([]);
  const [ordering, setOrdering] = useState("regDate");
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [EseqState, setEseqState] = useState("");
  const [isDescending1, setIsDescending1] = useState(true);
  const [isDescending2, setIsDescending2] = useState(false);
  const [isDescending3, setIsDescending3] = useState(true);
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  const [isModifyOpen, setIsModifyOpen] = useRecoilState(modalState2);
  const [coverImg, setCoverImg] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [bookDesc, setBookDesc] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [tagSelected, setTagSelected] = useState([]);
  const [eSeq, setEseq] = useState("");

  const [checkcclTf, setCheckcclTf] = useState(false);
  const [checkprofitTf, setCheckProfitTf] = useState(false);
  const [checkchangeTf, setCheckChangeTf] = useState(false);

  const qrCodePopup = (url) => {
    setIsOpen(true);
    setEseqState(url);
  };

  const jikjiDownLoad = (url) => {};

  const disclosure = async (publicInt, eSeq) => {
    formdata.append("eSeq", eSeq);
    if (publicInt === 0) {
      formdata.append("publicTF", 1);
    } else if (publicInt === 1) {
      formdata.append("publicTF", 0);
    }
    const result = await updateUserContentsPublic(formdata);
    if ( result.returnInt === 1 ) {
      // alert(`수정 완료되었습니다.`);
      refetch();
    } else {
      alert(result.resultMsg);
    }
    // getParam(ordering);
  };

  const editFNC = async (eSeq, coverUrl, bookTitle, bookDesc, cclTf, profitTf, changeTf, tagNames) => {
    console.log("editFNC");
    setIsModifyOpen(true);
    setCoverImg(coverUrl);
    setContentTitle(bookTitle);
    setEseq(eSeq);
    setBookDesc(bookDesc);
    setTagSelected(tagNames?.split(','));
    
    setCheckcclTf(cclTf===1?true:false);
    setCheckProfitTf(profitTf===1?true:false);
    setCheckChangeTf(changeTf===1?true:false);

    if ( cclTf===1 ) {
      setIsDisabled(false);
    }

    //화면 스크롤 숨김처리
    document.querySelector("body").style.overflow = "hidden";
  };

  const deleteFNC = async (eSeq) => {
    if (window.confirm("삭제하시겠습니까?")) {
      formdata.append("eSeq", eSeq);
      const result = await deleteBookContents(formdata);
      if ( result.returnInt === 1 ) {
        alert("삭제되었습니다.");
        refetch();
      } else {
        alert(result.resultMsg);
      }      
    }
  };

  const filterList = (text) => {
    switch (text) {
      case "contentName":
      default:
        setIsDescending1((prev) => !prev);
        break;
      case "regDate":
        setIsDescending2((prev) => !prev);
        break;
      case "publicTF":
        setIsDescending3((prev) => !prev);
        break;
    }
    setOrdering(text);
  };

  const getParam = useCallback(
    (content) => {
      let isDesc;
      switch (ordering) {
        case "contentName":
          isDesc = isDescending1;
          break;
        case "regDate":
        default:
          isDesc = isDescending2;
          break;
        case "publicTF":
          isDesc = isDescending3;
          break;
      }
      const array = sortingByPram(content, ordering, isDesc);
      setSortedData(array);
    },
    [ordering, isDescending1, isDescending2, isDescending3]
  );

  useEffect(() => {
    const content = contentData?.rows || [];
    getParam(content);
  }, [contentData, getParam]);

  const downloadImage = (url, name) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        // console.log("url", url);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("An error sorry"));
  };

  const updateLicense = async (eSeq) => {
    const bookDesc = document.querySelector("textarea[name=bookDesc]").value;
    const cclTf = document.querySelector("input[name=cclTf]").checked;
    const profitTf = document.querySelector("input[name=profitTf]").checked;
    const changeTf = document.querySelector("input[name=changeTf]").checked;
    const bookTag = tagSelected?.join(',')??'';


    if (bookDesc.replace(/ /g,'') === '') {
      alert('상세설명을 작성해주셔야 저장이 가능합니다.');
      return false;
    }

    formdata.append("eSeq", eSeq);
    formdata.append("bookDesc", bookDesc);
    formdata.append("cclTf", cclTf?1:0);
    formdata.append("profitTf", profitTf?1:0);
    formdata.append("changeTf", changeTf?1:0);
    formdata.append("bookTag", bookTag);

    // console.log("eSeq=", eSeq);
    // console.log("bookDesc=", bookDesc);
    // console.log("cclTf   =", cclTf?1:0);
    // console.log("profitTf =", profitTf?1:0);
    // console.log("changeTf=", changeTf?1:0);
    // console.log("bookTag=", bookTag);

    const result = await updateUserContentsDesc(formdata);
    if ( result.returnInt === 1 ) {
      alert("수정 완료되었습니다.");
      refetch();
    } else {
      alert(result.resultMsg);
    }
    closeModifyPopup();
  };

  const closeModifyPopup = ()=> {
    setIsModifyOpen(false);
      setIsDisabled(true);
      setCheckProfitTf(false);
      setCheckChangeTf(false);
      document.querySelectorAll(".rti--tag").forEach((tag)=>{tag.remove();});
      setTagSelected([]);
  };

  return (
    <>
      {isLoading && <Loading />}
      <Manager>
        {
          <table>
            <colgroup>
              <col width="10%" />
              <col width="24%" />
              <col width="8%" />
              <col width="20%" />
              <col width="14%" />
              <col width="10%" />
              <col width="7%" />
              <col width="7%" />
            </colgroup>
            <thead>
              <tr className="listTit">
                <th colSpan={2}>
                  <FilterBtn
                    isDescending={isDescending1}
                    className="filter"
                    onClick={() => {
                      filterList("contentName", isDescending1);
                    }}
                  >
                    콘텐츠 명<span className="orderingIcon"></span>
                  </FilterBtn>
                </th>
                <th>QR</th>
                <th>jik 파일</th>
                <th>
                  <FilterBtn
                    isDescending={isDescending2}
                    className="filter"
                    onClick={() => {
                      filterList("regDate", isDescending2);
                    }}
                  >
                    업로드 날짜
                    <span className="orderingIcon"></span>
                  </FilterBtn>
                </th>
                <th>
                  <FilterBtn
                    isDescending={isDescending3}
                    className="filter"
                    onClick={() => {
                      filterList("publicTF", isDescending3);
                    }}
                  >
                    공개여부
                    <span className="orderingIcon"></span>
                  </FilterBtn>
                </th>
                <th>수정</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {sortedData &&
                sortedData.map((list) => (
                  <tr key={list.eSeq}>
                    <td>
                      <div className="thumnail">
                        <img src={list.coverUrl} />
                      </div>
                    </td>
                    <td>
                      <div className="info">
                        <p>{list.bookTitle}</p>
                        {/* <p>이상 영원히 일월과 풀이 텍스</p>
                      <p>#최신 #최신 #최신 </p> */}
                      </div>
                    </td>
                    {/* <td><button className="editBtn"></button> </td>*/}
                    <td>
                      <QrCode
                        className="qrCode"
                        qrImg={list.qrcodeUrl}
                        onClick={() => {
                          qrCodePopup(list.qrcodeUrl);
                        }}
                      ></QrCode>
                    </td>
                    <td>
                      <span
                        className="jikjiName"
                        onClick={() => {
                          window.open(list.jikDownloadUrl);
                        }}
                      >
                        {list.fileNM}
                      </span>
                    </td>
                    <td>{list.regDate.split(" ")[0]}</td>
                    <td>
                      <Disclosure
                        onClick={() => {
                          disclosure(list.publicTF, list.eSeq);
                        }}
                        className={`switchBtn ${
                          list.publicTF === 1 ? "" : "off"
                        }`}
                      ></Disclosure>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          editFNC(list.eSeq, list.coverUrl, list.bookTitle, list.bookDesc, list.cclTf, list.profitTf, list.changeTf, list.tagNames);
                        }}
                        className="editBtn"
                      ></button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          deleteFNC(list.eSeq);
                        }}
                        className="deleteBtn"
                      ></button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        }
      </Manager>
      {isOpen ? (
        <Modal
          title="QR"
          width={tablet ? 192 : 192}
          qrImgPopup={true}
          // id={id}
          // input={inputComplain}
        >
          <PopupQrImg src={EseqState} className="popupQrImg"></PopupQrImg>
          <PopupQrBtn
            onClick={() => {
              downloadImage(EseqState, "QRimg");
            }}
            className="QrDownBtn"
          >
            이미지로 저장
          </PopupQrBtn>
        </Modal>
      ) : null}

      {isModifyOpen ? (
        <ModalContentManager
          title="수정"
          width={tablet ? 300 : 600}
          height={tablet ? 800 : 450}
          qrImgPopup={false}
          executeFunction={updateLicense}
          eSeq={eSeq}
          // id={id}
          // input={inputComplain}
        >
          <CclForm>
            <div className={"left"}>
              <ModalCoverImg src={coverImg}></ModalCoverImg>
              <ul>
                <li>
                  <div>CCL 설정</div>
                  <div>
                    <Toggle
                      height={20}
                      onChange={(event) => {
                        let isChecked = event.target.checked;
                        if (isChecked === false) {
                          setIsDisabled(true);
                          setCheckProfitTf(false);
                          setCheckChangeTf(false);

                        } else {
                          setIsDisabled(false);
                          setCheckProfitTf(true);
                          setCheckChangeTf(true);

                        }
                        setCheckcclTf(event.target.checked);
                      }}
                      sliderHeight={12}
                      sliderWidth={12}
                      translate={19}
                      width={40}
                      disabled={false}
                      name="cclTf"
                      value="1"
                      checked={checkcclTf}
                    />
                  </div>
                </li>
                <li>
                  <div>영리목적으로 이용</div>
                  <div>
                    <Toggle
                      height={16}
                      onChange={(event) => {
                        let isChecked = event.target.checked;
                        const isCheckChangeTf = document.querySelector("input[name=changeTf]").checked;
                        if (isChecked === false) {
                          if(!isCheckChangeTf) {
                            // setIsDisabled(true);
                            // setCheckcclTf(false);
                          }
                        } else {
                          if(isCheckChangeTf) {
                            // setCheckcclTf(true);                            
                          }
                        }
                        setCheckProfitTf(event.target.checked);
                      }}
                      sliderHeight={12}
                      sliderWidth={12}
                      translate={19}
                      width={38}
                      disabled={isDisabled}
                      name="profitTf"
                      value="1"
                      checked={checkprofitTf}
                    />
                  </div>
                </li>
                <li>
                  <div>변경 또는 2차 저작</div>
                  <div>
                    <Toggle
                      height={16}
                      onChange={(event) => {
                        let isChecked = event.target.checked;
                        const isCheckChangeTf = document.querySelector("input[name=profitTf]").checked;
                        if (isChecked === false) {
                          if(!isCheckChangeTf) {
                            // setIsDisabled(true);
                            // setCheckcclTf(false);
                          }
                        } else {
                          if(isCheckChangeTf) {
                            // setCheckcclTf(true);
                          }
                        }
                        setCheckChangeTf(event.target.checked);
                      }}
                      sliderHeight={12}
                      sliderWidth={12}
                      translate={19}
                      width={38}
                      disabled={isDisabled}
                      name="changeTf"
                      value="1"
                      checked={checkchangeTf}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h2>{contentTitle}</h2>
              {tablet ? (
                <ModalCoverImg
                  src={coverImg}
                  style={{ display: "block" }}
                ></ModalCoverImg>
              ) : null}
              <textarea
                name="bookDesc"
                rows={11}
                placeholder="상세설명을 작성해 주세요."
                defaultValue={bookDesc}
              ></textarea>
              <TagsInputStyle>
                <TagsInput
                  value={tagSelected}
                  onChange={setTagSelected}
                  name="bookTag"
                  placeHolder="태그 입력해 주세요."
                />
              </TagsInputStyle>
              {/* <textarea rows={2} placeholder="태그를 입력해 주세요."></textarea> */}
            </div>
          </CclForm>
        </ModalContentManager>
      ) : null}
    </>
  );
}
