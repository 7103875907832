export const LOCAL_STORAGE_KEY = {
    // 소셜 로그인 시에 로그인 유지가 눌려있을 경우
    // 로그인 완료 후 쿠키에 저장여부를 결정하기 위한 임시 값
    TEMP_SOCIAL_KEEP_LOGIN: 'temp_social_keep_login',
}

export const setTempSocialKeepLogin = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY.TEMP_SOCIAL_KEEP_LOGIN, true);
}

export const getTempSocialKeepLogin = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY.TEMP_SOCIAL_KEEP_LOGIN) || false;
}

export const removeTempSocialKeepLogin = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.TEMP_SOCIAL_KEEP_LOGIN)
}
