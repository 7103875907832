import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import backgroundImg_top from "../images/pages/Download/backgroundImg_top.png";
import backgroundImg_top_m from "../images/pages/Download/backgroundImg_top_m.png";
import backgroundImg_bottom from "../images/pages/Download/backgroundImg_bottom.png";
import backgroundImg_bottom_m from "../images/pages/Download/backgroundImg_bottom_m.png";
import download_icon from "../images/pages/Download/download_icon.svg";
import make_icon from "../images/pages/Download/make_icon.svg";
import round_1 from "../images/pages/Download/round_1.svg";
import round_2 from "../images/pages/Download/round_2.svg";
import round_3 from "../images/pages/Download/round_3.svg";
import jikji_logo from "../images/pages/Download/jikji_logo.svg";
import icon1 from "../images/pages/guide/icon1.svg";
import icon2 from "../images/pages/guide/icon2.svg";
import icon3 from "../images/pages/guide/icon3.svg";
import ButtonBlue from "../components/ButtonBlue";
import ButtonRound from "../components/ButtonRound";
import Carousel from "../components/Carousel";
import { useMediaQuery } from "react-responsive";

const WholeBg = styled.div`
  background: url(${backgroundImg_top}) center top / 100% auto no-repeat,
    url(${backgroundImg_bottom}) center bottom / 100% auto no-repeat;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    background: url(${backgroundImg_top_m}) center top / 100% auto no-repeat,
      url(${backgroundImg_bottom_m}) center bottom / 100% auto no-repeat;
  }
`;
const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1200px;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /* outline: 1px solid red; */
  .jikji_logo {
    width: 100px;
    height: 95px;
    margin: 90px auto 35px;
    background: url(${jikji_logo}) center / 100% auto no-repeat;
  }
  h1 {
    font-family: "NEXON Lv1 Gothic OTF";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 74px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #161616;
  }
  > p {
    font-family: "NEXON Lv1 Gothic OTF";
    font-size: 28px;
    line-height: 60px;
    margin-bottom: 32px;
  }
  .middleBox {
    display: flex;
    gap: 53px;
    margin: 114px auto 124px;
    .box {
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;
      gap: 27px;
      .roundIcon {
        width: 160px;
        height: 160px;
      }
      .desc {
        text-align: center;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #161616;
      }
      &:nth-child(1) {
        width: 335px;
        .roundIcon {
          background: url(${round_1}) center/100% no-repeat;
        }
      }
      &:nth-child(2) {
        width: 335px;
        .roundIcon {
          background: url(${round_2}) center/100% no-repeat;
        }
      }
      &:nth-child(3) {
        width: 346px;
        .roundIcon {
          background: url(${round_3}) center/100% no-repeat;
        }
      }
    }
  }
  h2 {
    position: relative;
    width: 264px;
    margin-bottom: 100px;

    font-weight: 700;
    font-size: 48px;
    line-height: 74px;
    color: #161616;
    text-align: center;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 37px;
      width: 410px;
      height: 1px;
      background-color: #9e9e9e;
    }

    &::before {
      right: 264px;
    }
    &::after {
      left: 264px;
    }
  }
  .guide {
    width: 100%;
    padding: 0 39px;
    .btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 19px;
      .roundBtns {
        display: flex;
        gap: 24px;
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    min-width: 100%;
    .jikji_logo {
      width: 84px;
      height: 80px;
      margin: 50px auto 16px;
    }
    h1 {
      font-size: 30px;
      line-height: 40px;
    }
    > p {
      font-size: 14px;
      line-height: 14px;
      margin-top: 8px;
    }
    .middleBox {
      flex-direction: column;
      gap: 35px;
      margin: 50px auto 58px;
      .box {
        flex-direction: row;
        gap: 12px;
        .roundIcon {
          width: 47px;
          height: 47px;
        }
        .desc {
          text-align: justify;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: fit-content;
        }
      }
    }
    h2 {
      width: auto;
      margin-bottom: 45px;
      font-size: 20px;
      line-height: 31px;
      &::before,
      &::after {
        top: 15px;
        /* width: 100%; */
        width: calc((100vw - 100%) / 2 - 32px);
      }
      &::before {
        right: 112px;
      }
      &::after {
        left: 112px;
      }
    }
    .guide {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 16px 60px;

      .btns {
        justify-content: center;
        width: 100%;
        .roundBtns {
          width: 100%;
          justify-content: center;
        }
      }
      .slick-slider .slick-slide > div {
        height: 100%;
      }
    }
  }
`;

export default function Download() {
  const navigate = useNavigate();
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  const onWebLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Layout isScroll>
      <WholeBg>
        <Inner>
          <div className="jikji_logo"></div>
          <h1>직지 저작도구{tablet && <br />}다운로드</h1>
          <p>PC에서 다운로드 및 실행 가능합니다.</p>
          {/* <button
            className="downloadBtn"
            onClick={() => {
              onWebLink("https://kpub.co.kr/jikji_download/Jik-ji%20Editor%20setup%20(win%20x86).exe");
            }}
          >
            <span className="icon"></span> 다운로드
          </button> */}
          {!tablet && (
            <ButtonBlue
              imgSrc={download_icon}
              text="다운로드"
              onClickCallback={() => {
                onWebLink("https://kpub.co.kr/jikji_download/Jik-ji%20Editor%20setup%20(win%20x86).exe");
              }}
            />
          )}
          <div className="middleBox">
            <div className="box">
              <div className="roundIcon"></div>
              <div className="desc NEXONLv1">
                손쉬운 텍스트 <br />
                입력 및 스타일 적용
              </div>
            </div>
            <div className="box">
              <div className="roundIcon"></div>
              <div className="desc NEXONLv1">
                이미지, 동영상, URL 등 <br />
                멀티미디어 자료 삽입
              </div>
            </div>
            <div className="box">
              <div className="roundIcon"></div>
              <div className="desc NEXONLv1">
                제작자가 스크립트 개발 없이 <br />
                응용 가능한 인터렉션 <br />
                컴포넌트 set 지원
              </div>
            </div>
          </div>
          <h2 className="NEXONLv1">직지 사용법</h2>
          <div className="guide">
            <div className="btns">
              {!tablet && (
                <ButtonBlue
                  imgSrc={make_icon}
                  text="콘텐츠 만들기"
                  onClickCallback={() => {
                    navigate("/guide ");
                  }}
                />
              )}
              <div className="roundBtns">
                <ButtonRound
                  onClickCallback={() => onWebLink("https://me2.do/FpM308YM")}
                  bgColor="#EBF8FF"
                  hover="#32AAFA"
                  imgSrc={icon1}
                  text="매뉴얼"
                />
                <ButtonRound
                  onClickCallback={() => onWebLink("http://tovsoft.com:8080/jikji/tutorial")}
                  bgColor="#FFFADB"
                  hover="#FFA914"
                  imgSrc={icon2}
                  text="튜토리얼"
                />
                <ButtonRound
                  onClickCallback={() => onWebLink("https://www.youtube.com/@tovsoft6303/videos")}
                  bgColor="#FFF1F0"
                  hover="#FF4733"
                  imgSrc={icon3}
                  text="유튜브"
                />
              </div>
            </div>
            <Carousel page="download" />
            {tablet && (
              <ButtonBlue
                imgSrc={make_icon}
                text="콘텐츠 만들기"
                onClickCallback={() => {
                  navigate("/guide ");
                }}
              />
            )}
          </div>
        </Inner>
      </WholeBg>
    </Layout>
  );
}
