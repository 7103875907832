export const RETURN_INT_WITH_TOKEN = {
    success: 1, // 성공
    fail: 0,    // 실패 (DB 오류, 토큰 인증 실패)
    expire: -1, // 토큰 만료
    expire2: -2 // Ebook 상세정보 요청 토큰 만료
}

export const RETURN_MSG_WITH_TOKEN = {
    fail: '토큰인증 실패',
    expire: '토큰만료',
}