import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { getMemberInfo } from "../core/api";
import camera_black from "../images/pages/MyLibrary/camera_black.svg";

import { DellPhotoState } from "../routes/Account";
import { getThumbnailColor } from "../util";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  div.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${(props) => (props.bg ? "none" : props.bgColor)};
    background-image: url(${(props) => props.bg});
    background-position: center;
    background-size: cover;
    border: none;
    margin: auto 0;
    font-family: "Pretendard-Light";
    font-size: 36px;
    color: ${(props) => props.theme.gray800};
  }
  div.profileBtn {
    position: relative;
    top: 25px;
    left: -25px;
    width: 28px;
    min-width: 28px;
    height: 28px;
    background: url(${camera_black});
    cursor: pointer;
  }
  div.profileMenu {
    position: relative;
    top: 60px;
    left: -40px;
    display: ${(props) => (props.isOpenProfileMenu ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 70px;
    font-size: 14px;
    line-height: 17px;
    color: #656975;
    border: 1px solid ${(props) => props.theme.gray300};
    border-radius: 3px;
    background-color: #fff;
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: none;
      color: ${(props) => props.theme.gray500};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.gray800};
        background-color: ${(props) => props.theme.gray200};
      }
    }
    > div {
      border-top: 1px solid #f0f0f0;
    }
  }
  em {
    display: block;
    min-height: 24px;
    margin: 5px 0px;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    color: #fa2337;
  }
  @media ${({ theme }) => theme.device.tablet} {
    div.profileMenu {
      width: 87px;
      height: 67px;
    }
    em {
      line-height: 16px;
    }
  }
`;

export default function SetProfile({ text, register, reset, errors }) {
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
  const toggleProfileMenu = () => {
    setIsOpenProfileMenu((prev) => !prev);
  };
  // const [prevPhoto, setPrevPhoto] = useState(null);

  const { setIsDellPhoto } = useContext(DellPhotoState);
  const [newPhoto, setNewPhoto] = useState(null);
  const [bgColor, setBgColor] = useState("");
  const photo = async () => {
    const result = await getMemberInfo();
    const mSeq = result.row.mSeq;
    const phothURL = result?.row?.photoUrl;
    if (phothURL) {
      // setPrevPhoto(phothURL);
      setNewPhoto(phothURL);
    }
    setBgColor(getThumbnailColor(mSeq));
  };

  useEffect(() => {
    photo();
  }, []);
  const onLoadFile = (e) => {
    let reader = new FileReader();

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }

    reader.onloadend = () => {
      const fileUrl = reader.result;

      if (fileUrl) {
        setNewPhoto(fileUrl);
        setIsDellPhoto(false);
      }
    };
  };
  const clearFile = () => {
    setIsOpenProfileMenu(false);
    setNewPhoto("");
    setIsDellPhoto(true);
    reset((formValuses) => ({
      ...formValuses,
      userPhoto: [],
    }));
  };
  return (
    <Wrap isOpenProfileMenu={isOpenProfileMenu} bg={newPhoto} bgColor={bgColor}>
      <div className="profile">{newPhoto ? "" : text}</div>
      <div className="profileBtn" onClick={toggleProfileMenu}></div>
      <div className="profileMenu">
        <input
          id="file2"
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          {...register}
          onInput={onLoadFile}
        />
        <label
          htmlFor="file2"
          id="file2"
          className="profile"
          onClick={toggleProfileMenu}
        >
          사진 변경
        </label>
        <div onClick={clearFile}>사진 삭제</div>
      </div>
      <em>{errors?.userPhoto?.message}</em>
    </Wrap>
  );
}
