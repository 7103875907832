import styled from "styled-components";
import x from "../images/common/modal/x.svg";

const S = {};

S.Wrapper = styled.div`
  width: 100%;
`;
S.InfoSection = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 15px;
  width: 100%;
  z-index: 1;
  padding-top: 64px;
  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 25px;
  }
`;

S.PosterImgWrap = styled.div`
  position: relative;
  width: 424px;
  height: 320px;
  background-image: url(${(props) => props.bgurl});
  background-color: ${(props) => (props.bgurl == false ? "#d9d9d9" : "none")};
  background-size: cover;
  background-position: center 0px;
  background-repeat: no-repeat;
  border-radius: 10px;

  img {
    position: absolute;
    right: 6px;
    bottom: 6px;
  }

  /* @media ${({ theme }) => theme.device.tablet} {
      width: 100%;
      aspect-ratio: 152 / 194;
    } */
`;
S.PosterImgMobile = styled(S.PosterImgWrap)`
  width: 100%;
  height: auto;
  aspect-ratio: 375/283;
  border-radius: 0;
`;
S.EbookInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 704px;
  .upper {
    .flexBox {
      display: flex;
      justify-content: space-between;
      /* align-items: center; */
    }
    .regDate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      line-height: 16px;
      color: #9a9ba7;
      margin-bottom: 2px;
    }
    .ebookTitle {
      max-width: 648px;
      font-family: "Pretendard-SemiBold";
      font-size: 28px;
      line-height: 40px;
      color: #0f0f10;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 8px;
    }
    .nickname {
      display: flex;
      align-items: center;
      font-family: "Pretendard-Medium";
      font-size: 20px;
      line-height: 24px;
      color: #262626;

      .subscribe {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 32px;
        font-family: "Pretendard-SemiBold";
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        background: #000000;
        border: 1.5px solid #000000;
        border-radius: 6px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
    .tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 16px;
      a {
        padding: 5px 16px;
        color: #4e4f52;
        font-size: 15px;
        line-height: 18px;
        background: #f0f0f0;
        border-radius: 32px;
      }
    }
    .ccl {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      margin: 20px 0px 10px 0;
    }
    .help {
      line-height: 1.5;
      font-size: 0.8rem;
      color: gray;
      a {
        color: #f74551;
      }
    }  
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    .upper {
      .regDate {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 3px;
      }
      .ebookTitle {
        width: 100%;
        font-size: 24px;
        line-height: 28px;
      }
      .nickname {
        font-size: 16px;

        .subscribe {
          width: 52px;
          height: 28px;
          font-size: 14px;
          line-height: 17px;
          margin-left: 10px;
        }
      }
      .tags a {
        padding: 4px 14px;
        font-size: 12px;
        line-height: 14px;
        background: #f0f0f0;
        border-radius: 32px;
      }
    }
  }
`;
S.NickPhoto = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 11px;
  background: ${(props) => (props.bgUrl ? `url(${props.bgUrl}) center/100% no-repeat` : `${props.bgColor}`)};
  @media ${({ theme }) => theme.device.tablet} {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
`;
S.Under = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    gap: 16px;
    .goBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 50px;
      border-radius: 6px;
      font-family: "Pretendard-SemiBold";
      color: #fff;
      background-color: ${(props) => props.theme.point01};
      font-size: 22px;
      cursor: pointer;

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${(props) => props.theme.point01};
      }
    }
    .bookmark {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 50px;
      background: #ffffff;
      border: 1.5px solid #f74551;
      border-radius: 6px;
      font-family: "Pretendard-SemiBold";
      font-size: 22px;
      line-height: 26px;
      color: #f74551;
      cursor: pointer;
      &.yes {
      }

      &:hover {
        background: linear-gradient(0deg, rgba(247, 69, 81, 0.1), rgba(247, 69, 81, 0.1)), #fff;
      }
    }
    .favor,
    .linkIn {
      width: 32px;
      height: 32px;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    padding: 8px;
    background-color: #fff;
    z-index: 1;
    .left {
      width: 100%;
      justify-content: space-between;
      gap: 8px;

      .goBtn,
      .bookmark {
        height: 40px;
        width: 49%;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`;
S.HoverBtn = styled.span`
  background: url(${(props) => props.offImg}) center/100% no-repeat;
  cursor: pointer;
  &.complain {
    width: 40px;
    height: 40px;
  }
  &:hover {
    background-image: url(${(props) => props.hoverImg});
  }
  @media ${({ theme }) => theme.device.tablet} {
    &.complain {
      width: 28px;
      height: 28px;
    }
  }
`;
S.Right = styled.div`
  display: flex;
  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    height: 37px;
    width: 73px;
    font-size: 14px;
    line-height: 17px;
    color: #303441;
    text-align: center;

    .icon {
      width: 18px;
      height: 18px;
    }
    &:nth-child(2) {
      border-left: 1px solid #edeff5;
      border-right: 1px solid #edeff5;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    justify-content: space-between;
    gap: 12px;
    > * {
      flex-direction: row;
      justify-content: center;
      width: auto;
      height: auto;
      font-size: 12px;
      line-height: 14px;

      .icon {
        width: 16px;
        height: 16px;
      }
      &:nth-child(2) {
        border: none;
      }
    }
  }
`;
S.PopupWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 50;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: ${(props) => (props.bgDark ? "rgba(0,0,0,0.5)" : "transparent")};
`;
S.PopupShere = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.scaleDown ? "359px" : "424px")};
  background-color: #fff;
  border: 1px solid #dbdbdb;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  transform-origin: center;
  div.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    h3 {
      font-family: "pretendard-SemiBold";
      font-size: 22px;
      line-height: 26px;

      color: ${(props) => props.theme.gray1000};
      margin-left: 16px;
    }
    .close {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 44px;
      background: url(${x}) center/28px auto no-repeat;
      margin-right: 4px;
      cursor: pointer;
    }
  }
  div.links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;
    margin-top: -10px;
  }
  div.copyUrl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.gray100};
    border: 1px solid ${(props) => props.theme.gray200};
    /* margin-bottom: 10px; */
    margin: 0 16px 16px 16px;

    cursor: pointer;
    :hover .copyBtn,
    :hover .copyBtn svg {
      color: ${(props) => props.theme.point01};
    }
    span {
      font-family: "pretandard-Medium";
      font-size: 12px;
      line-height: 14px;
      color: ${(props) => props.theme.gray400};
      margin-left: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    div.copyBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 84px;
      background-color: ${(props) => props.theme.gray200};
      color: ${(props) => props.theme.gray500};
      font-size: 16px;
      line-height: 19px;
    }
  }
`;
S.LinkIcon = styled.span`
  margin: 32px 12px;
  width: 48px;
  height: 48px;
  background: url(${(props) => props.bgImg}) center/100% no-repeat;

  cursor: pointer;
  border-radius: 50%;
`;
S.MessageBase = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

S.MessageBox = styled.div`
  display: flex;
  padding: 40px 30px;
  border-radius: 20px;
  background-color: #fff;
`;

S.Cclicon = styled.div`
  background: url(${(props) => props.bgImg}) center/cover no-repeat;
  width: 36px;
  height: 36px;
  cursor: help;
`;
export default S;
