import styled from "styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import banner from "../images/pages/guide/banner.svg";
import content1_1 from "../images/pages/guide/content1_1.svg";
import content1_2 from "../images/pages/guide/content1_2.png";
import content1_3 from "../images/pages/guide/content1_3.png";
import content2_1 from "../images/pages/guide/content2_1.svg";
import content3_1 from "../images/pages/guide/content3_1.svg";
import content5_1 from "../images/pages/guide/content5_1.svg";
import icon1 from "../images/pages/guide/icon1.svg";
import icon2 from "../images/pages/guide/icon2.svg";
import icon3 from "../images/pages/guide/icon3.svg";
import { Link } from "react-router-dom";
import Carousel from "../components/Carousel";
import { useMediaQuery } from "react-responsive";
import ButtonRound from "../components/ButtonRound";

const Banner = styled.section`
  width: 100%;
  min-width: 1200px;
  height: 1024px;
  aspect-ratio: 1200/1024;
  margin: 0 auto;
  box-sizing: border-box;
  background: linear-gradient(105.88deg, #fff494 18.29%, #ffe666 100%), #fff7b3;
  .banner-wrap {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    background-image: url(${(props) => props.img});
    background-position: bottom 30px right 20px;
    background-size: 790px 790px;
    background-repeat: no-repeat;

    h2 {
      display: flex;
      flex-direction: column;
      padding: 200px 0 0 96px;
      font-family: "NEXON Lv1 Gothic";
      font-style: normal;
      font-weight: 700;
      font-size: 80px;
      line-height: 92px;
      color: #262626;
      .small {
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    height: auto;
    margin: 0;
    aspect-ratio: 375/200;
    .banner-wrap {
      width: 100%;
      background-position: bottom 15px right 16px;
      background-size: 50%;
      h2 {
        padding: 24px 0 0 16px;
        font-size: 32px;
        line-height: 37px;
        .small {
          font-size: 22px;
          line-height: 25px;
          margin-top: 6px;
        }
      }
    }
  }
`;
const Content = styled.div`
  width: 100%;
  /* padding-top: 270px; */

  &.first {
    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 180px;
      width: 1122px;
      height: 504px;
      border: 1px solid #c5c7cc;
      border-radius: 10px;
      margin: 0px auto 80px;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        > img {
          height: 400px;
        }
        > p {
          font-size: 20px;
          line-height: 24px;
          color: #727585;
          em {
            font-family: "pretendard-SemiBold";
            color: #191a1f;
          }
        }
        &:nth-child(1) {
          /* width: 423px; */
          img {
            width: 333px;
          }
        }
        &:nth-child(2) {
          img {
            width: 340px;
          }
        }
      }
    }
  }

  &.third {
    .btns {
      position: absolute;
      bottom: -74px;
      width: 500px;
      display: flex;
      gap: 24px;
      margin-top: 30px;
    }
  }
  &.fourth {
    .hashtag {
      display: flex;
      justify-content: space-between;
      width: 758px;
      height: 336px;
      > div {
        display: flex;
        flex-direction: column;
        gap: 24px;
        span {
          width: fit-content;
          font-family: "Pretendard-Medium";
          font-size: 30px;
          line-height: 36px;
          color: #303441;
          padding: 6px 26px;
          border-radius: 30px;
        }
        &:nth-child(1) {
          span {
            background: #cce3f5;
          }
        }
        &:nth-child(2) {
          span {
            background: #fad7de;
          }
        }
        &:nth-child(3) {
          span {
            background: #faebb9;
          }
        }
      }
    }
  }
  .flex-box-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 427px;
    margin: 120px 0 80px;
    background-image: ${(props) =>
      props.bgImg ? `url(${props.bgImg})` : `none`};
    background-position: top right 16px;
    background-size: 640px auto;
    background-repeat: no-repeat;
    .bg {
      display: none;
    }
    .flex-box {
      display: flex;
      gap: 10px;
      .num {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #f0f2f5;
        border-radius: 6px;
        font-family: "Pretendard-SemiBold";
        font-size: 32px;
        line-height: 38px;
        color: #303441;
      }
      .text-box {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .text-1 {
          font-family: "Pretendard-SemiBold";
          font-size: 32px;
          line-height: 38px;
          color: #303441;
          word-break: keep-all;
        }
        .text-2 {
          font-family: "Pretendard-Medium";
          font-size: 16px;
          line-height: 19px;
          color: #727585;
        }
      }
    }
  }
  .btn {
    display: block;
    cursor: pointer;

    &.red {
      width: 450px;
      height: 50px;
      margin: 0 auto 80px;
      font-family: "Pretendard-SemiBold";
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: #ffffff;
      background: #f74551;
      border: none;
      border-radius: 6px;
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0.1)
          ),
          ${(props) => props.theme.point01};
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 60px;
    border-top: 1px solid #f0f2f5;
    .flex-box-wrap {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      margin: 0px 0 20px;
      background: none;
      .flex-box {
        .num {
          width: 26px;
          height: 26px;
          font-size: 22px;
          line-height: 26px;
        }
        .text-box {
          gap: 6px;
          .text-1 {
            font-size: 22px;
            line-height: 26px;
          }
          .text-2 {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .bg {
        display: block;
        width: 100%;
        margin: 10px 0 0 0;
        aspect-ratio: 343/228;
        background-image: ${(props) =>
          props.bgImg ? `url(${props.bgImg})` : `none`};
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
      }
    }
    &.first {
      border-top: none;
      .box {
        flex-direction: column;
        width: calc(100% - 22px);
        height: auto;
        gap: 30px;
        padding: 20px;
        margin: 0px auto 40px;

        > div {
          width: 100%;
          gap: 10px;
          > img {
            width: auto;
            height: auto;
          }
          > p {
            font-size: 15px;
            line-height: 18px;
            text-align: center;
          }
          &:nth-child(1) {
            img {
              /* min-width: 184px; */
              width: 67%;
              aspect-ratio: 184/200;
            }
          }
          &:nth-child(2) {
            img {
              /* min-width: 170px; */
              width: 61%;
              aspect-ratio: 170/200;
            }
          }
        }
      }
    }
    &.second {
    }
    &.third {
      .slick-slider {
        margin: 0 auto 40px;
        .slick-slide {
          > div {
            height: 100%;
            > div {
              height: 100%;
            }
          }
        }
      }
      .btns {
        position: static;
        width: fit-content;
        margin: 20px auto 0;
      }
    }
    .btn {
      &.red {
        width: 100%;
        height: 44px;
        margin: 0 auto 40px;

        font-size: 20px;
        line-height: 24px;
      }
    }
    &.fourth {
      .hashtag {
        width: 100%;
        height: auto;
        padding: 70px 24px 20px;
        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
          span {
            width: fit-content;
            font-family: "Pretendard-Medium";
            font-size: 13px;
            line-height: 16px;
            color: #303441;
            padding: 2.5px 10px;
            border-radius: 11px;
          }
          &:nth-child(1) {
            span {
              background: #cce3f5;
            }
          }
          &:nth-child(2) {
            span {
              background: #fad7de;
            }
          }
          &:nth-child(3) {
            span {
              background: #faebb9;
            }
          }
        }
      }
    }
  }
`;
// const RoundBtn = styled.button`
//   background: ${(props) =>
//     props.icon ? `${props.bgColor} url(${props.icon}) center left 20px/24px auto no-repeat` : "none"};
//   border: 2px solid ${(props) => props.bgColor};
//   &:hover {
//     border: 2px solid ${(props) => props.hover};
//   }
//   @media ${({ theme }) => theme.device.tablet} {
//     background-size: 16px auto;
//     background-position: center left 12px;
//   }
// `;

export default function Guide() {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  const onWebLink = (url) => {
    window.open(url, "_blank");
  };

  const Btns = () => {
    return (
      <span className="btns">
        <ButtonRound
          onClickCallback={() => onWebLink("https://me2.do/FpM308YM")}
          bgColor="#EBF8FF"
          hover="#32AAFA"
          imgSrc={icon1}
          text="매뉴얼"
        />
        <ButtonRound
          onClickCallback={() =>
            onWebLink("http://tovsoft.com:8080/jikji/tutorial")
          }
          bgColor="#FFFADB"
          hover="#FFA914"
          imgSrc={icon2}
          text="튜토리얼"
        />
        <ButtonRound
          onClickCallback={() =>
            onWebLink(
              "https://www.youtube.com/playlist?list=PLONYAo8uJT6zm7lEXOGVDoeLNC4vv124S"
            )
          }
          bgColor="#FFF1F0"
          hover="#FF4733"
          imgSrc={icon3}
          text="유튜브"
        />
      </span>
    );
  };

  return (
    <Layout>
      <Banner img={banner}>
        <div className="banner-wrap">
          <h2>
            크리에이터 <em className="small">활동 가이드</em>
          </h2>
        </div>
      </Banner>
      <Container>
        <Content className="first" bgImg={content1_1}>
          <div className="flex-box-wrap">
            <div className="flex-box">
              <span className="num">1</span>
              <span className="text-box">
                <span className="text-1">
                  KPUB 회원으로 다양한 콘텐츠를 <br />
                  제작하고 즐겨보세요.
                </span>
                <span className="text-2">
                  KPUB에서 다양한 콘텐츠도 만나고, <br />
                  저작도구 ‘직지‘로 나만의 콘텐츠를 제작할 수 있습니다.
                </span>
              </span>
            </div>
            <div className="bg"></div>
          </div>
          <div className="box">
            <div>
              <img src={content1_2} alt="" />
              <p>
                KPUB홈 <em>상단 아이콘&gt;로그인&gt;회원가입</em>을 선택합니다.
              </p>
            </div>
            <div>
              <img src={content1_3} alt="" />
              <p>
                회원가입 완료 후 <em>로그인</em>을 선택합니다.
              </p>
            </div>
          </div>
          <Link to={`/login`}>
            <button className="btn red">회원가입 바로가기</button>
          </Link>
        </Content>
        <Content className="second" bgImg={content2_1}>
          <div className="flex-box-wrap">
            <div className="flex-box">
              <span className="num">2</span>
              <span className="text-box">
                <span className="text-1">
                  최고의 저작도구 ‘직지’로 <br />
                  손쉽게 나만의 콘텐츠를 제작해 보세요.
                </span>
                <span className="text-2">
                  직지는 쉽고 재미있게 콘텐츠를 제작할 수 있도록 도와드립니다.
                </span>
              </span>
            </div>
            <div className="bg"></div>
          </div>
          <Link to={`/download`}>
            <button className="btn red">직지 저작도구 다운로드</button>
          </Link>
        </Content>
        <Content className="third" bgImg={content3_1}>
          <div className="flex-box-wrap">
            <div className="flex-box">
              <span className="num">3</span>
              <span className="text-box">
                <span className="text-1">
                  ‘직지’를 사용하는 방법? <br />
                  다~ 알려 드립니다.
                </span>
                <span className="text-2">어렵지 않아요. 함께 해요.</span>
                {!tablet && (
                  <span className="btns">
                    <ButtonRound
                      onClickCallback={() =>
                        onWebLink("https://me2.do/FpM308YM")
                      }
                      bgColor="#EBF8FF"
                      hover="#32AAFA"
                      imgSrc={icon1}
                      text="매뉴얼"
                    />
                    <ButtonRound
                      onClickCallback={() =>
                        onWebLink("http://tovsoft.com:8080/jikji/tutorial")
                      }
                      bgColor="#FFFADB"
                      hover="#FFA914"
                      imgSrc={icon2}
                      text="튜토리얼"
                    />
                    <ButtonRound
                      onClickCallback={() =>
                        onWebLink(
                          "https://www.youtube.com/playlist?list=PLONYAo8uJT6zm7lEXOGVDoeLNC4vv124S"
                        )
                      }
                      bgColor="#FFF1F0"
                      hover="#FF4733"
                      imgSrc={icon3}
                      text="유튜브"
                    />
                  </span>
                )}
              </span>
            </div>
            <div className="bg"></div>
            {tablet && <Btns />}
          </div>
          <Carousel className="slider" />
        </Content>
        <Content className="fourth">
          <div className="flex-box-wrap">
            <div className="flex-box">
              <span className="num">4</span>
              <span className="text-box">
                <span className="text-1">
                  다양한 콘텐츠를 <br />
                  만들 수 있습니다.
                </span>
                <span className="text-2">
                  상상하는 콘텐츠를 직접 만들어보세요.
                </span>
              </span>
            </div>
            <div className="hashtag">
              <div>
                <span>포토북</span>
                <span>온라인청첩장</span>
                <span>웹진</span>
                <span>교육콘텐츠</span>
                <span>노하우북</span>
              </div>
              <div>
                <span>팬픽</span>
                <span>팬아트 모음집</span>
                <span>브로셔</span>
                <span>카탈로그</span>
                <span>아이 작품 모음</span>
              </div>
              <div>
                <span>문집</span>
                <span>홈페이지</span>
                <span>매뉴얼</span>
                <span>유튜브 모음</span>
                <span>여행 책자</span>
              </div>
            </div>
          </div>
        </Content>
        <Content className="fifth" bgImg={content5_1}>
          <div className="flex-box-wrap">
            <div className="flex-box">
              <span className="num">5</span>
              <span className="text-box">
                <span className="text-1">
                  여러분이 제작한
                  <br />
                  멋진 콘텐츠를 공유할 수 있습니다.
                </span>
                <span className="text-2">
                  최고의 콘텐츠 크리에이터로 도약해보세요.
                </span>
              </span>
            </div>
            <div className="bg"></div>
          </div>
        </Content>
      </Container>
    </Layout>
  );
}
