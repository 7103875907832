import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { scrollBtnState } from "../atoms";
import ThumbnailTemplate, { ThumbnailDummy } from "./ThumbnailTemplate";
import arrowRight from "../images/common/button/button_arrow_right_gray.svg";
import arrowLeft from "../images/common/button/button_arrow_left_gray.svg";
import { usePressAndHold } from "../hook/usePressAndHold";


const Wrap = styled.aside`
  display: flex;
  position: relative;
  width: 100%;
  height: max-content;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  border-radius: 4px;
  -webkit-overflow-scrolling: touch;

  ul {
    display: flex;
    position: relative;
    left: 30px;
    width: calc(100% - 60px);
    height: max-content;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: transparent;
    }
    .thumbnail {
      &:not(:last-child) {
        margin-right: 10px;
      }
      a {
        display: inline-block;
        width: 212px;
      }
    }
    @media ${({ theme }) => theme.device.tablet} {
      left: 20px;
      width: calc(100% - 40px);
    }
  }
`;

const ListBtn = styled.button`
  position: absolute;
  top: 58px;
  width: 48px;
  height: 48px;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  &.left {
    left: 6px;
    background: #ffffff url(${arrowLeft}) center / 12px auto no-repeat;
  }
  &.right {
    right: 6px;
    background: #ffffff url(${arrowRight}) center / 12px auto no-repeat;
  }
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
  @media ${({ theme }) => theme.device.tablet} {
    top: 70px;
    width: 32px;
    height: 32px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    &.left {
      left: 4px;
      background-size: 8px auto;
    }
    &.right {
      right: 4px;
      background-size: 8px auto;
    }
  }
`;


export default function AsideTemplate({ data }) {
  const userListRef = useRef(null);
  const navButtonLeftRef = useRef(null);
  const navButtonRightRef = useRef(null);
  const { startCounter, stopCounter } = usePressAndHold();
  // const [scollBtn, setScollBtn] = useRecoilState(scrollBtnState);
  const similarBox = useRef();
  const location = useLocation();
  const scrollAmount = 222;

  const onClickLeft = () => {
    scrollHorizontally(userListRef.current, -scrollAmount);
  };

  const onClickRight = () => {
    scrollHorizontally(userListRef.current, scrollAmount);
  };

  const onScrollUserList = () => {
    // http://jsfiddle.net/y8Y32/25/
    const userListElement = userListRef.current;
    const containerInnerWidth = userListElement.clientWidth;
    const containerScrollLeft = userListElement.scrollLeft;
    const containerScrollRight =
      userListElement.scrollLeft + containerInnerWidth;
    const contentWidth = userListElement.scrollWidth;

    showElementRef(navButtonLeftRef);
    showElementRef(navButtonRightRef);

    if (containerScrollLeft <= 0) {
      showElementRef(navButtonLeftRef, false);
    }

    if (containerScrollRight >= contentWidth) {
      showElementRef(navButtonRightRef, false);
    }
  };

  const scrollHorizontally = (element, amount) => {
    element.scrollBy({ left: amount, behavior: "smooth" });
  };

  const showElementRef = (elementRef, value = true) => {
    const element = elementRef.current;

    if (element) {
      if (value) {
        element.classList.add("show");
      } else {
        element.classList.remove("show");
      }
    }
  };
  
  useEffect(() => {
    similarBox.current.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (userListRef.current) {
      const userListElement = userListRef.current;

      userListElement.onscroll = onScrollUserList;
    }
  }, []);

  useEffect(() => {
    if (userListRef.current) {
      onScrollUserList();
    }
  }, [data]);


  return (
    <Wrap ref={similarBox}>
      <ListBtn
        className="left"
        ref={navButtonLeftRef}
        onMouseDown={() => startCounter(onClickLeft)}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
        onTouchStart={() => startCounter(onClickLeft)}
        onTouchEnd={stopCounter}
      />
      <ul ref={userListRef}>
        {data?.slice(0, 10).map((content, i) => (
          <li
            className="thumbnail"
            key={i}
            ref={data.length - 1 === i ? undefined : undefined}
          >
            <ThumbnailTemplate
              bgurl={content.coverUrl}
              title={content.bookTitle}
              publisher={content.nickName}
              view={content.viewCnt}
              id={content.temSeq}
              favor={content.favorCnt}
              bookUrl={content.bookUrl}
              manualUuid={content.manualUuid}
              downCnt={content.downCnt}
              data={content}
            ></ThumbnailTemplate>
          </li>
        ))}
      </ul>
      <ListBtn
        className="right"
        ref={navButtonRightRef}
        onMouseDown={() => startCounter(onClickRight)}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
        onTouchStart={() => startCounter(onClickRight)}
        onTouchEnd={stopCounter}
      />
    </Wrap>
  );
}
