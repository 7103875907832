import { atom } from "recoil";

export const themeState = atom({
  key: "themeState",
  default: "light",
});

export const modalState = atom({
  key: "modalState",
  default: false,
});

export const modalState2 = atom({
  key: "modalState2",
  default: false,
});

export const scrollBtnState = atom({
  key: "scrollBtnState",
  default: false,
});

export const mobileCommentsAtom = atom({
  key: "mobileCommentsAtom",
  default: false,
});

export const selectedSubscribedUserState = atom({
  key: "selectedSbuscribedUserState",
  default: "",
})

export const mberInfoAtom = atom
({
  key: "mberInfo",
  default: {mSeq:0},
});