import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import arrowDown from "../images/common/button/button_arrow_down_black.svg";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Pretendard-Regular";
  font-size: 18px;
  line-height: 26px;
  color: #303441;
  div {
    margin-bottom: 16px;
    margin-top: 16px;
    width: 100%;
    
    &.fold_off {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      width: 100%;
      height: auto;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 2;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  button {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: #242424;
    cursor: pointer;
    .arrow {
      display: inline-block;
      width: 10px;
      height: 5px;
      margin-left: 4px;
      background: url(${arrowDown}) center / 100% auto no-repeat;
    }
    &.foldBtn {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
`;

export default function Information({ data }) {
  const [foldState, setFoldState] = useState(true);
  const { id } = useParams();
  const bookDesc = data;

  const convertDataToHTML = (data) => {
    const convertedData = data.replace(/(?:\r\n|\r|\n)/g, '<br>')
    return convertedData;
  }

  const folding_open = (e) => {
    setFoldState((perv) => !perv);
  };

  useEffect(() => {
    if ( bookDesc.length>300 ) {
      setFoldState(false);
    } else {
      setFoldState(true);
    }
    
  }, [id]);

  return (
    <Wrap>
      <div className={foldState ? "fold_off" : ""}>
        {bookDesc ? (
          <div dangerouslySetInnerHTML={ {__html: convertDataToHTML(bookDesc)} }></div>
        ) : "등록된 소개글이 없습니다."}
      </div>
      { bookDesc && bookDesc.length>300 ? (
        <button onClick={folding_open} className={foldState ? "" : "foldBtn"}>
        {foldState ? "더보기" : "접기"}
        <span className="arrow"></span>
      </button>
      ) : null}
    </Wrap>
  );
}
