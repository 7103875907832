import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getThumbnailColor } from "../util";
import { useEffect } from "react";

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  .nickName {
    white-space: nowrap;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.gray1000};
  }
`;

const Profile = styled.li.attrs((props) => ({
  // 매번 값이 바뀌는 속성만 style로
  style: {
    backgroundColor: getThumbnailColor(props.mSeq),
  },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
  }
`;

export default function UserCard(props) {
  const location = useLocation();
  const { userData, onSelectSubscribedUserId } = props;
  const { mSeq, nickName, thumbUrl } = userData;

  useEffect(()=>{
    if(document
      .querySelectorAll("[id^=contentUser]").length>0){
        const contentUser = document.querySelectorAll("[id^=contentUser]")[0];
        contentUser.style.fontWeight = "bold";
        contentUser.style.color = "#E45457";
      }
  },[]);

  const selectMSeq = (mSeq) => {
    onSelectSubscribedUserId(mSeq);
    if(document
      .querySelectorAll("[id^=contentUser]").length>0){
        document
        .querySelectorAll("[id^=contentUser]")
        .forEach(function (item, index) {
          if (item.id === `contentUser${mSeq}`) {
            item.style.fontWeight = "bold";
            item.style.color = "#E45457";
          } else {
            item.style.fontWeight = "inherit";
            item.style.color = "#0f0f10";
          }
        });
      }
  };

  return (
    <Link
      // to={
      //   userData.mSeq ? `/yourLibrary/${mSeq}` : `${location.pathname}${location.search}`
      // }
      onClick={() => {
        selectMSeq(mSeq);
      }}
      state={{ userData }}
    >
      <User>
        <Profile className="profile" mSeq={mSeq}>
          {thumbUrl && thumbUrl.length > 0 ? (
            <img src={thumbUrl} alt={nickName} />
          ) : (
            nickName.charAt(0)
          )}
        </Profile>
        <div className="nickName" id={`contentUser${mSeq}`}>
          {nickName}
        </div>
      </User>
    </Link>
  );
}
