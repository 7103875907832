export const COOKIE_NAME = {
  ACCESS_TOKEN: 'ACCESSTOKEN',
  REFRESH_TOKEN: 'REFRESHTOKEN',
  KEEP_LOGIN: 'KEEPLOGIN'
}

export const setCookie = (name, value, expires) => {
  document.cookie = `${name}=${value}; ${expires}; path=/;`; 
}

export const getCookie = (name) => {
  let cookies = document.cookie.split("; ");
  let cookie_value;
  
  cookies.forEach(function (cookie) {
    if (cookie.split("=")[0] == name) {
      cookie_value = cookie.split("=")[1];
    }
  });
  return cookie_value;
};

export const delCookie = (name) => {
  let thisDate = new Date();
  thisDate.setDate(thisDate.getDate() - 1);
  
  const expires = `expires=${thisDate}`;

  document.cookie = `${name}= ; ${expires}; path=/;`;
}

export const setLoginCookie = (keepLogin, accessToken, refreshToken) => {
  let thisDate = new Date();
  let monthDate = new Date(thisDate);
  // let hourDate = new Date(thisDate);
  
  monthDate.setDate(thisDate.getDate() + 30);
  // hourDate.setHours(thisDate.getHours() + 1);
  
  const monthExpires = keepLogin ? `expires=${monthDate}` : "";
  // const hourExpires = keepLogin ? `expires=${hourDate}` : "";

  setCookie(COOKIE_NAME.ACCESS_TOKEN, accessToken, monthExpires);
  setCookie(COOKIE_NAME.REFRESH_TOKEN, refreshToken, monthExpires);
  setCookie(COOKIE_NAME.KEEP_LOGIN, keepLogin, monthExpires);
}

export const delLoginCookie = () => {
  delCookie(COOKIE_NAME.ACCESS_TOKEN);
  delCookie(COOKIE_NAME.REFRESH_TOKEN);
  delCookie(COOKIE_NAME.KEEP_LOGIN);
}