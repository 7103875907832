import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
//import Portal from "./portal";
import blankImg from "../images/blank.png";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";

const Portal = (props) => {
  return createPortal(props.children, document.getElementById("portal"));
};
function PopupModal({
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  data,
}) {
  const BASE_URL = process.env.REACT_APP_API_HOST;

  const [popupWidth, setPopupWidth] = useState(300);
  const [popupHeight, setPopupHeight] = useState(300);
  const [popupURL, setPopupURL] = useState("");
  const [popupTarget, setPopupTarget] = useState("_blank");
  const [popupImage, setPopupImage] = useState(blankImg);

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  // 이전방문 날짜
  const VISITED_BEFORE_DATE = localStorage.getItem("VisitCookie");
  // 현재 날짜
  const VISITED_NOW_DATE = new Date().getTime(); //Math.floor(new Date().getDate());

   // console.log(VISITED_BEFORE_DATE)
  // console.log(VISITED_NOW_DATE)
  // localStorage.removeItem('VisitCookie')

  useEffect(() => {
    const regex =
      /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;

    setPopupWidth(data[0]?.wWidth);
    setPopupHeight(data[0]?.wHeight);
    setPopupURL(data[0]?.url);
    setPopupImage(data[0]?.popupImage);

    if (regex.test(data[0]?.url)) {
      if (data[0]?.url.indexOf(BASE_URL) !== -1) {
        setPopupTarget("_self");
      } else {
        setPopupTarget("_blank");
      }
    } else {
      setPopupTarget("_self");
    }

  }, []);

  useEffect(() => {
    // 팝업 오늘 하루닫기 체크
    if (VISITED_BEFORE_DATE !== null) {
      const DateHourDiff = Math.floor((VISITED_NOW_DATE-VISITED_BEFORE_DATE)/(1000*60*60));
      // 날짜가 같거나 지났을 경우 노출
      if (DateHourDiff>24) {
        localStorage.removeItem("VisitCookie");
        onClose(true);
      }
      // 날짜가 다를경우 비노출
      if (DateHourDiff<=24) {
        if (document.getElementById("CloseStyle") !== null) {
          afterDisplyNone();          
        }
        onClose(false);
      }
    }
  }, [VISITED_BEFORE_DATE]);

  // 하루동안 팝업 닫기
  const Dayclose = (e) => {
    if (onClose) {
      onClose(e);

      const expiry = new Date();
      // +1일 계산
      const expiryDate = expiry.getTime();
      // 로컬스토리지 저장
      localStorage.setItem("VisitCookie", expiryDate);

      if (document.getElementById("CloseStyle") !== null) {
        afterDisplyNone();      
      }
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  /**
   * Home.jsx에서  useLayoutEffect를 사용해도 팝업창으로 인한 깜박임이 발생하여 
   * afterDisplayNone을 작성하여 적용함. 
   */
  const afterDisplyNone = () => {
    document.getElementById("CloseStyle").style.display = "none";
    document.getElementById("ModalOverlay").style.display = 'none';
    document.getElementById("ModalWrapper").style.display = 'none';      
  };

  return (
    <Portal elementId="modal-root">
      <ModalOverlay visible={visible} id="ModalOverlay"/>
      <ModalWrapper
        className={className}
        onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
        id="ModalWrapper"
      >
        <ModalInner
          tabIndex="0"
          className="modal-inner"
          popupWidth={popupWidth}
          popupHeight={popupHeight}
        >
          <ModalInner2>
            <ImgStyle>
              <Link
                to={popupURL ? popupURL : "#"}
                rel="noopener noreferrer"
                target={popupTarget ? popupTarget : "_blank"}
                cursor="pointer"
              >
                <img
                  src={popupImage}
                  style={{ width: `"100%"`, height: "100%" }}
                  alt=""
                />
              </Link>
            </ImgStyle>
            {closable && (
              <CloseStyle id="CloseStyle">
                <Close className="modal-close" onClick={Dayclose}>
                  오늘 하루 닫기
                </Close>
                <Close className="modal-close" onClick={close}>
                  닫기
                </Close>
              </CloseStyle>
            )}
          </ModalInner2>
        </ModalInner>
      </ModalWrapper>
    </Portal>
  );
}

PopupModal.propTypes = {
  visible: PropTypes.bool,
};

const ModalInner2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgStyle = styled.div``;

const CloseStyle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #282828;
  width: 210px;
  padding: 10px 12px;
  border-radius: 0 0 15px 15px;
  color: #ffffff;
  font-size: 12px;
  width: 100%;
`;

const Close = styled.span`
  cursor: pointer;
`;

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  // background-color: #fff;
  // border-radius: 10px;
  width: ${(props) => props.popupWidth}px;
  height: ${(props) => props.popupHeight}px;
  //max-width: 480px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 40px 20px;

  @media ${({ theme }) => theme.device.tablet} and (orientation:portrait) {
    width: 100%;
    height: auto;
  }

  @media ${({ theme }) => theme.device.tablet} and (orientation:landscape) {
    width: auto;
    height: 100%;
  }

  @media ${({ theme }) => theme.device.mobile} and (orientation:portrait) {
    width: 100%;
    height: auto;
  }

  @media ${({ theme }) => theme.device.mobile} and (orientation:landscape) {
    width: auto;
    height: 100%;
  }
`;

export default React.memo(PopupModal);
