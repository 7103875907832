import { useRecoilState } from "recoil";
import styled from "styled-components";
import { scrollBtnState } from "../atoms";

const ToTopBtn = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: ${(props) => (props.bottom ? props.bottom : "24px")};
  right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${(props) => props.theme.bgColor};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

export default function TopBtn({ bottom }) {
  const [scollBtn, setScollBtn] = useRecoilState(scrollBtnState);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTimeout(() => {
      setScollBtn(false);
    }, 10);
  };
  return (
    <ToTopBtn onClick={scrollToTop} bottom={bottom}>
      <svg width="15" height="21" viewBox="0 0 18 26" fill="none">
        <path
          d="M2.115 15.615L7.5 10.245L7.5 25.5L10.5 25.5L10.5 10.245L15.885 15.63L18 13.5L9 4.5L-5.24537e-07 13.5L2.115 15.615ZM-9.83506e-07 3L18 3L18 -2.69415e-06L-1.11464e-06 -1.90735e-06L-9.83506e-07 3Z"
          fill="black"
        />
      </svg>
    </ToTopBtn>
  );
}
