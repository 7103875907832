import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import Layout from "../components/Layout";
import Container from "../components/Container";
import { getUserLibraryList } from "../core/api";
import ThumbGrid from "../components/ThumbGrid";
import EbookContainer from "../components/EbookContainer";
import { useNavigate, useParams } from "react-router-dom";
import { confirmLogin, getThumbnailColor, sortingByPram, useUser } from "../util";
import { useSubscribe, useUnSubscribe } from "../hook/useSubscribe";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import Subfilter from "../components/Subfilter";
import Hashtag from "../components/Hashtag";

const Wrapper = styled.div``;

const ContentBtn = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.bgColor};
  font-family: "Pretendard-SemiBold";
  font-size: 15px;
  line-height: 18px;
  background: ${(props) => props.theme.point01};
  border-radius: 4px;
  padding: 7px 10.5px;
  cursor: pointer;
  span:nth-child(1) {
    margin-right: 5px;
  }
  &.off {
    color: #262626;
    background: #d3d5db;
    /*pointer-events: none;*/
  }
  &:hover,
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${(props) => props.theme.point01};
    color: white;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 7px 16.5px;
    font-size: 15px;
    line-height: 18px;
    margin-top: 7px;
    /* margin: 7px 0 24px 0; */
    /* margin-bottom: 24px; */
  }
`;

const MessageBase = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const MessageBox = styled.div`
  display: flex;
  padding: 40px 30px;
  border-radius: 20px;
  background-color: #fff;
`;

export default function YourLibrary() {
  const { user, accessToken } = useUser();
  const { mSeq } = useParams();
  const navigate = useNavigate();
  const formdata = new FormData();
  formdata.append("mSeq", mSeq);

  const { data, isLoading } = useQuery(["userLibrary", accessToken], () => getUserLibraryList(formdata));
  const userData = data?.row;
  const nickName = userData?.nickName ?? "";
  const thumbUrl = userData?.thumbUrl ?? "";
  const isSubscribe = userData?.isSubscribe ?? "";

  const { requestScribe, responseMessage, setResponseMessage } = useSubscribe();
  const { requestUnScribe, responseMessageUs, setResponseMessageUs } = useUnSubscribe();
  const [thumbColor, setThumbColor] = useState("");
  const [sortedData, setSortedData] = useState([]);
  // const [param, setParam] = useState("regDate");

  // 정렬 데이터 초기화
  useEffect(() => {
    if (data?.rows) {
      setSortedData(data?.rows);
    }
  }, [data]);

  useEffect(() => {
    // console.log("mSeq", mSeq);
    if (!userData) {
      // navigate("/");
    } else {
      setThumbColor(getThumbnailColor(mSeq));
    }
  }, [navigate, userData, mSeq]);

  const onClickSubscribe = () => {
    if (user) {
      if(isSubscribe){
        requestUnScribe(mSeq);
      }else{
        requestScribe(mSeq);
      }
    } else {
      confirmLogin();
    }
  };

  // 정렬 종류 선택
  const getParam = (opt) => {
    // setParam(opt);
    const array = sortingByPram(data?.rows, opt);
    setSortedData(array);
  };

  return (
    <>
      <Layout>
        <Wrapper>
          {isLoading ? <Loading /> : null}
          <Hashtag />
          <PageTitle title={`${nickName}의 룸`} kind="myLibrary" thumbUrl={thumbUrl} thumbColor={thumbColor}>
            {isSubscribe !== 3 && (
              <ContentBtn onClick={onClickSubscribe} className={isSubscribe === 1 ? "off" : ""}>
                {isSubscribe === 1 ? "구독중" : "구독하기"}
              </ContentBtn>
            )}
          </PageTitle>
          <Container>
            <Subfilter filter={true} getParam={getParam} />
            <EbookContainer>
              {data && data.rows && sortedData?.length > 0 ? (
                <ThumbGrid data={sortedData} />
              ) : (
                <div className="noneSearched">저장된 콘텐츠가 없습니다.</div>
              )}
            </EbookContainer>
          </Container>
        </Wrapper>
      </Layout>
      {responseMessage.length > 0 && (
        <MessageBase
          onClick={() => {
            setResponseMessage("");
            window.location.reload();
          }}
        >
          <MessageBox>{responseMessage}</MessageBox>
        </MessageBase>
      )}
      {responseMessageUs.length > 0 && (
        <MessageBase
          onClick={() => {
            setResponseMessageUs("");
            window.location.reload();
          }}
        >
          <MessageBox>{responseMessageUs}</MessageBox>
        </MessageBase>
      )}
    </>
  );
}
