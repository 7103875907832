import { useRecoilState } from "recoil";
import styled from "styled-components";
import { modalState2 } from "../atoms";
import { useMediaQuery } from "react-responsive";
import XBtn from "../images/common/modal/x.svg";
import { appendEbookComplain } from "../core/api";
import { useNavigate } from "react-router-dom";

const ModalWrap = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  z-index: 50;
  backdrop-filter: blur(20px);
  
  /* width:  */
  @media ${({ theme }) => theme.device.tablet} {
    overflow-y: scroll;
    align-items: flex-start;
    padding: 10px 0;
  }
`;
const Modal = styled.div`
  position: relative;
  /* height: 184px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: initial;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
  border-radius: 10px;
  overflow: hidden;

  &.qrImgPopup {
    width: 192px;
    /* height: 298px; */
    overflow: hidden;
    justify-content: flex-start;
    h2 {
      display: block;
      margin: 16px;
      font-family: "Pretendard-Medium";
      font-size: 22px;
      line-height: 26px;
      color: #303441;
    }

    & > div:nth-child(2) h2 {
      margin: 0 16px 16px 0;
    }
  }
  p {
    margin: 32px auto 72px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #303441;
    text-align: center;
  }
  button.modalBtn {
    width: 100%;
    height: 40px;
    border: none;
    border-top: 1px solid ${(props) => props.theme.gray300};
    background-color: #fff;
    font-family: "pretendard-SemiBold";
    color: ${(props) => props.theme.gray600};
    font-size: 18px;
    position: absolute;
    bottom: 0px;
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.point01};
      background-color: ${(props) => props.theme.gray100};
    }
  }
  textarea {
    border: solid 1px red;
    width: 100%;
    /* height: 88px; */
    background-color: ${(props) => props.theme.gray100};
    border: 1px solid ${(props) => props.theme.gray200};
    border-radius: 4px;
    outline: none;
    padding: 8px;
    margin: 0 auto 10px;
    resize: none;
    ::placeholder {
      font-size: 16px;
      color: ${(props) => props.theme.gray400};
    }
  }

`;
const Head = styled.div`
  /* position: absolute; */
  /* top: 0px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  font-size: 18px;
  .titleZone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    font-family: "pretendard-Medium";
  
  }
  h2 {
    font-family: "pretendard-Medium";
    font-size: 12px;
    line-height: 26px;
    color: ${(props) => props.theme.gray1000};
    margin-left: 16px;
  }
  h3 {
    font-family: "pretendard-Medium";
    font-size: 32px;
    line-height: 26px;
    color: ${(props) => props.theme.gray1000};
    margin-left: 0;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    background: url(${XBtn}) center/28px auto no-repeat;
    margin-right: 4px;
    cursor: pointer;
  }
`;

export default function ModalStatEbookList({
  title,
  width,
  height,
  children,
  isDark = false,
  setIsModifyOpen,
  totalCnt
}) {

  const mobile = useMediaQuery({ query: `(max-width: 520px)` });
  const navigate = useNavigate();


  return (
    <ModalWrap
      mobile={mobile ? true : null}
      style={{
        backgroundColor: isDark ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.23)",
      }}
    >
      <Modal
        style={{
          width: width + "px",
          height: height + "px",
        }}
        className={"qrImgPopup"}
      >
        {title ? (
          <Head>
            <div className="titleZone">
              <h2>{title}</h2>
              <h3>{totalCnt}</h3>
            </div>
            <div
              className="close"
              onClick={() => {
                setIsModifyOpen(false);
                document.querySelector("body").style.overflow = "auto";
                console.log("close");
              }}
            >
              <img src={XBtn} alt="" />
            </div>
          </Head>
        ) : null}
        {children}
      </Modal>
    </ModalWrap>
  );
}
