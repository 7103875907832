import { useQuery } from "@tanstack/react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import EbookList from "../components/EbookList";
// import BannerSlider from "../components/BannerSlider";
import BannerContainer from "../components/BannerContainer";
import IconContainer from "../components/IconContainer";
import Layout from "../components/Layout";
import Container from "../components/Container";
import Hashtag from "../components/Hashtag";
import AD from "../components/AD";
import { getPopularList, getRecentUploadList, getSuggestionList, getPopupInfo } from "../core/api";
import Loading from "../components/Loading";
import AD_1 from "../images/components/AD/AD_1.svg";
import AD_2 from "../images/components/AD/AD_2.svg";
import AD_1_m from "../images/components/AD/AD_1_m.svg";
import AD_2_m from "../images/components/AD/AD_2_m.svg";
import PopupModal from "../components/PopupModal";

const Banner = styled.section`
  width: 100%;
  height: 624px;
  aspect-ratio: 1200/624;
  margin: 28px auto 0;
  box-sizing: border-box;
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    height: auto;
    margin: 0;
  }
`;
const IconList = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  margin: 40px auto;
`;

const BottomAD = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 72px;
  }
`;

export default function Home() {
  // 팝업공지 노출여부
  const [modalVisible, setModalVisible] = useState(false);

  // E-book List
  const { data: suggestion, isLoading: isLoadingSuggestion } = useQuery(["suggestion"], getSuggestionList);
  const [suggestions, setSuggestions] = useState([]);

  const { data: recentUpLoad, isLoading } = useQuery(["recentUpLoad"], getRecentUploadList);
  const [recentUpLoads, setRecentUpLoads] = useState([]);

  const { data: popular, isLoading: isLoadingPopular } = useQuery(["popular"], getPopularList);
  const [popularContents, setPopularContents] = useState([]);

  const {data: popup, isLoading: isLoadingPopup } = useQuery(["popup"], getPopupInfo);
  const [popups, setPopups] = useState([]);

  useEffect(() => {
    if (suggestion?.rows) {
      setSuggestions((old) => [...suggestion?.rows]);
    }
  }, [suggestion]);
  useEffect(() => {
    if (recentUpLoad?.rows) {
      setRecentUpLoads((old) => [...recentUpLoad?.rows]);
    }
  }, [recentUpLoad]);
  useEffect(() => {
    if (popular?.rows) {
      setPopularContents((old) => [...popular?.rows]);
    }
  }, [popular]);
  useLayoutEffect(() => {
    if (popup?.rows.length>0) {
      setPopups((old) => [...popup?.rows]);
      setModalVisible(true);
      lockScroll();
    }
  }, [popup]);

  /*/////////////////////////////////////////////////////
  //2023-09-06 공지팝업 작업
  // START ////////////////////////////////////////////*/
  // const openModal = () => {
  //     setModalVisible(true)
  // }
  const closeModal = () => {
      setModalVisible(false)
      openScroll();
      
  }
  
  const lockScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = 'hidden';
  }

  const openScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = 'auto';
  }
  

  /*/ END ////////////////////////////////////////////////
  //2023-09-06 공지팝업 작업
  //////////////////////////////////////////////////////*/
  
  return (
    <Layout>
      {isLoading || isLoadingPopular || isLoadingSuggestion ? <Loading /> : null}
      <Hashtag />
      <Banner>
        <BannerContainer />
      </Banner>
      <IconList>
        <IconContainer />
      </IconList>
      <Container>
        <EbookList
          title={"발견!"}
          description={"KPUB이 재미있는 콘텐츠를 발견했어요."}
          data={suggestions}
          name={"suggestion"}
          needFilter={false}
          linkTo={"/moreEbook"}
          moreContents={true}
        />
        <EbookList
          title={"인기 콘텐츠"}
          description={"인기 있는 콘텐츠를 만나보세요."}
          data={popularContents}
          name={"popular"}
          needFilter={false}
          linkTo={"/contents"}
          options={{ ordering: 1 }}
          moreContents={true}
        />
        <EbookList
          title={"최신 콘텐츠"}
          description={"따끈따끈 새로 올라온 콘텐츠를 만나보세요."}
          data={recentUpLoads}
          name={"recentUpLoad"}
          needFilter={false}
          linkTo={"/contents"}
          options={{ ordering: 0 }}
          moreContents={true}
        />
        <BottomAD>
          <AD imgSrc={[AD_1, AD_1_m]} linkTo={"/guide"} />
          <AD imgSrc={[AD_2, AD_2_m]} linkTo={"/downLoad"} />
        </BottomAD>
        {modalVisible && (
          <PopupModal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal} data={popups}></PopupModal>
        )}
      </Container>
    </Layout>
  );
}
