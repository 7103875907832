import styled from "styled-components";

const Wraper = styled.button`
  display: block;
  padding: 8px 18px 8px 48px;
  font-family: "Pretendard-Medium";
  font-size: 20px;
  line-height: 24px;
  color: #262626;
  border-radius: 72px;
  cursor: pointer;
  background: ${(props) =>
    props.imgSrc ? `${props.bgColor} url(${props.imgSrc}) center left 20px/24px auto no-repeat` : "none"};
  border: 2px solid ${(props) => props.bgColor};
  &:hover {
    border: 2px solid ${(props) => props.hover};
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 7.5px 12px 7.5px 34px;
    font-size: 14px;
    line-height: 17px;
    color: #262626;
    border-radius: 72px;
    background-size: 16px auto;
    background-position: center left 12px;
  }
`;

export default function ButtonRound({ bgColor, hover, imgSrc, text, onClickCallback }) {
  return (
    <Wraper onClick={() => onClickCallback()} bgColor={bgColor} hover={hover} imgSrc={imgSrc}>
      {text}
    </Wraper>
  );
}
