import styled from "styled-components";

const BannerWrap = styled.footer`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 98px;
  background: #fdf6f7;
  @media ${({ theme }) => theme.device.mobilelg} {
    flex-direction: column;
  }
  strong {
    font-weight: 600;
    font-size: 22px;
    color: ${(props) => props.theme.gray1000};
    @media ${({ theme }) => theme.device.labtop} {
      font-size: 18px;
    }
  }
`;
const LinkTo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  width: 240px;
  height: 50px;
  right: 16%;
  background-color: ${(props) => props.theme.point01};
  border-radius: 6px;
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  cursor: pointer;
  @media ${({ theme }) => theme.device.desktop} {
    right: 5%;
  }
  @media ${({ theme }) => theme.device.labtop} {
    width: 150px;
    height: 35px;
    font-size: 16px;
  }
  @media ${({ theme }) => theme.device.mobilelg} {
    position: static;
    margin-top: 10px;
  }
`;

export default function EventBanner({ text, linkText }) {
  return (
    <BannerWrap>
      <strong>{text}</strong>
      {linkText ? (
        <LinkTo>
          <span>{linkText}</span>

          <svg
            width="20"
            height="15"
            viewBox="0 0 25 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 0L13.07 1.393L20.65 9H0.5V11H20.65L13.07 18.573L14.5 20L24.5 10L14.5 0Z"
              fill="white"
            />
          </svg>
        </LinkTo>
      ) : null}
    </BannerWrap>
  );
}
