import { useCallback, useRef } from "react";

export function useInfiniteScroll(isLoading, hasMore, setCurrentPage) {
  const observer = useRef();

  // 로드된 자료 중 마지막 ebook 섬네일에 IntersectionObserver 기능이 있는 ref를 추가하여
  // 스크롤 시 마지막 ebook 섬네일이 브라우저 뷰포트에 보이면 currentPage 값을 변경하여
  // useSearchData가 다음 페이지의 자료를 로드하게 함으로 무한 스크롤 구현
  // TODO: 사용자 목록과 ebook 목록의 페이징을 별개로 다루는 api 필요
  const lastElementRef = useCallback((node) => {
    if (isLoading) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage((old) => old + 1);
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [setCurrentPage, isLoading, hasMore]);

  return { lastElementRef };
}
