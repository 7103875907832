import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { sampleData } from "../sampleData";
import icon_p from "../images/pages/view/icon_p.svg";
import icon_preview from "../images/pages/view/icon_preview.svg";
import icon_manual from "../images/pages/view/icon_manual.svg";
import { useIsLogin, useUser } from "../util";
import { send } from "../core/api";
import { getCookie } from "../core/cookie";

const ViewWarp = styled.div`
  width: 818px;
  height: 357px;
  padding: 32px;
  h1 {
    font-family: "Pretendard-Medium";
    font-size: 22px;
    line-height: 26px;
    color: #0f0f10;
    margin-bottom: 20px;
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    img {
      display: block;
      width: 330px;
      height: 249px;
      object-fit: contain;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
    }
    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 400px;
      .infomBox {
        border: 1px solid #dbdbdb;
        border-radius: 6px;
        padding: 0 16px;
        box-sizing: border-box;
        div {
          display: flex;
          align-items: center;
          height: 39px;
          font-family: "Pretendard-Medium";
          font-size: 15px;
          line-height: 18px;
          border-bottom: 1px solid #f0f0f0;
          > span:first-child {
            width: 123px;
            color: #757575;
          }
          > span:last-child {
            color: #262626;
          }
          &:first-child span:nth-child(2) {
            display: flex;
            align-items: center;
            gap: 4px;
            .icon_p {
              display: block;
              width: 20px;
              height: 19px;
              background: url(${icon_p}) center / contain no-repeat;
            }
          }
        }
      }
      .buttonWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px 8px;
        > * {
          font-family: "Pretendard-SemiBold";
          font-weight: 600;
          font-size: 22px;
          line-height: 26px;
          border-radius: 6px;
        }
        button{
          cursor:pointer;
        }
        button:nth-child(1) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 400px;
          height: 50px;
          background: #007aff;
          color: #ffffff;
          &:hover {
            background: #005dd6;
          }
        }
        button:nth-child(2),
        button:nth-child(3) {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          width: 196px;
          height: 50px;
          color: #757575;
          background: #ffffff;
          border: 1px solid #c0c5d1;
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.04),
                rgba(0, 0, 0, 0.04)
              ),
              #ffffff;
          }
          .icon {
            width: 28px;
            height: auto;
            aspect-ratio: 1/1;
          }
        }
        button:nth-child(2) .icon {
          background: url(${icon_preview}) center / contain no-repeat;
        }
        button:nth-child(3) .icon {
          background: url(${icon_manual}) center / contain no-repeat;
        }
      }
    }
  }
`;
export default function View() {
    
  const { user } = useUser();
  const BASE_URL = process.env.REACT_APP_API_HOST;
  
  const data = JSON.parse(localStorage.getItem("popupDownloadData"));
  const id = data.temSeq;
  const bookUrl = data.bookUrl;
  const manualUuid = data.manualUuid;
  
  useEffect(() => {}, []);

  function downloadTemplate() {
    if(user){
      window.open(`${BASE_URL}/template/down/${id}`, "_blank");
      window.close();
    }else{
      alert("로그인 후 다운로드해주세요.");
    }
    
  }

  function downloadTemplateManual() {
    if(user){
      window.open(`${BASE_URL}/template/download/${manualUuid}`, "_blank");
      window.close();
    }else{
      alert("로그인 후 다운로드해주세요.");
    }
    
  }

    //2023-08-29 leeho :: 썸네일 하단에 뷰어 연동 버튼 추가.
    const onWebLink = (url) => {
      const cookie = getCookie("ACCESSTOKEN");
      const _url = cookie ? `${url}?token=${cookie}` : url;
      window.open(_url, "_blank");
      window.close();
    };
  
    const showEbook = (event) => {
      event.preventDefault();
      event.stopPropagation();
      onWebLink(bookUrl);
    };

  
  return (
    <ViewWarp>
      <h1>{data.templateName}</h1>
      <div className="flexBox">
        <img src={data.coverUrl} alt="" />
        <div className="column">
          <div className="infomBox">
            <div>
              <span>작성자</span>
              <span>
                <span className="icon_p"></span>
                {data.nickName}
              </span>
            </div>
            <div>
              <span>업로드 날짜</span>
              <span>{data.regDate.slice(0, 10)}</span>
            </div>
            <div>
              <span>다운로드</span>
              <span>{data.downCnt}</span>
            </div>
          </div>
          <div className="buttonWrap">
            <button type="button" onClick={downloadTemplate}>다운로드</button>
            <button type="button" onClick={showEbook}>
              <span className="icon"></span> 미리 보기
            </button>
            <button type="button" onClick={downloadTemplateManual}>
              <span className="icon"></span> 메뉴얼
            </button>
          </div>
        </div>
      </div>
    </ViewWarp>
  );
}