import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getMemberInfo } from "../core/api";
import { getCookie } from "../core/cookie";
import { getThumbnailColor, useUser } from "../util";
import { useRecoilState } from "recoil";
import { mberInfoAtom } from "../atoms";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "30px")};
  height: ${(props) => (props.width ? props.width : "30px")};
  border-radius: 50%;
  border: ${(props) => (props.borderColor ? `2px solid ${props.borderColor}` : `none`)};
  background-color: ${(props) => (props.url ? "none" : props.bgColor)};
  background-image: url(${(props) => props.url});
  background-position: center;
  background-size: cover;
  color: ${(props) => props.theme.gray1000};
`;

export default function ProfileIcon({ width }) {
  const { accessToken } = useUser();
  const { data, refetch } = useQuery(["memberData", accessToken], () => getMemberInfo());
  const mSeq = data?.row?.mSeq ?? "";
  const [bgColor, setBgColor] = useState("");
  const [nickName, setNickName] = useState("");
  const [thumbUrl, setThumbUrl] = useState("");
  const loginType = data?.row?.loginType ?? "";
  const [borderColor, setBorderColor] = useState("");
  // const navigate = useNavigate();
  const [mberInfo, setMberInfo] = useRecoilState(mberInfoAtom);

  const setIcon = useCallback(() => {
    // refetch();

    let noCacheThumbUrl = data?.row?.thumbUrl;
    if (noCacheThumbUrl) {
      noCacheThumbUrl = `${noCacheThumbUrl}?_at=${Math.random()}`;
    }
    const nickName = data?.row?.nickName?.charAt(0);
    setNickName(nickName);
    setThumbUrl(noCacheThumbUrl);
    setBgColor(getThumbnailColor(mSeq));
    setBorderColor(getLoginType(loginType));

    setMberInfo({...mberInfo, mSeq:mSeq});
    
  }, [data?.row?.nickName, data?.row?.thumbUrl, loginType, mSeq]);

  const getLoginType = (type) => {
    let color;
    switch (type) {
      case "구글":
        color = "#EA4335";
        break;
      case "네이버":
        color = "#03c75a";
        break;
      case "카카오":
        color = "#FBE300";
        break;
      default:
        color = "#1D75BD";
    }
    return color;
  };

  useEffect(() => {
    setIcon();
  }, [setIcon]);

  return (
    <Wrap
      bgColor={bgColor}
      url={thumbUrl}
      width={width ? width : null}
      borderColor={borderColor}
    >
      {thumbUrl ? null : nickName}
    </Wrap>
  );
}
