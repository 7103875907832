import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SSOFail() {
    const navigate = useNavigate();

    useEffect(() => {
                    
        if( window.confirm('로그인에 실패했습니다. 로그인 페이지로 이동할까요?') )
        {
            navigate('/Login');
        } else {
            navigate('/');
        }
        
    }, []);

    return null;
}