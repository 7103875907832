import { useEffect, useState } from "react";
import { getTemplateContentsList } from "../core/api";
import { appendUnique, getRequestBody } from "../util";

export function useTemplateData(ordering, page) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentData, setContentData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setContentData([]);
  }, []);

  useEffect(() => {
    async function requestData(requestBody) {
      try {
        const res = await getTemplateContentsList(requestBody);

        if (res.returnInt === 1) {
          setContentData((old) => appendUnique([...old], res.rows, (a, b) => a.temSeq === b.temSeq));
          setHasMore(res.totalCnt > page);
        } else {
          setHasMore(false);
          setError(true);
        }
      } catch (err) {
        setError(err);
      }

      setIsLoading(false);
    }

    const requestBody = getRequestBody({ ordering, page });

    setIsLoading(true);
    setError(false);
    requestData(requestBody);
  }, [ordering, page]);

  return { isLoading, error, contentData, hasMore };
}
