import styled from "styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import eventPage from "../images/pages/Event/eventPage1.png";
import eventPageM from "../images/pages/Event/eventPage_m_1.png";

import { useMediaQuery } from "react-responsive";
import Hashtag from "../components/Hashtag";

const Img = styled.img`
  display: block;
  margin-top: ${(props) => (props.tablet ? "0" : "29px")};
  width: 100%;
`;

export default function Event() {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Layout>
      <Hashtag />
      {!tablet ? (
        <Container>
          <Img src={eventPage} alt="" />
        </Container>
      ) : (
        <Img src={eventPageM} alt="" tablet={tablet} />
      )}
    </Layout>
  );
}
