import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import arrow from "../images/components/horizontalInput/selectBox_arrow.svg";

const Wrap = styled.div`
  position: relative;
  display: inline-block;
  /* max-width: 436px; */
  width: 100%;
  height: 35px;
  font-size: 16px;
  color: ${(props) => props.theme.gray500};
  padding: 0 19px;

  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 4px;
  background: url(${arrow}) center right 19px no-repeat;

  z-index: 1;

  .selected {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: #0f0f10;
  }
  ul {
    position: absolute;
    width: calc(100% + 2px);
    left: 0;
    margin: 4px 0 0 -1px;
    background: #ffffff;
    /* gray/gray-300 */

    border: 1px solid ${(props) => props.theme.gray300};
    border-radius: 4px;
    cursor: pointer;
    li {
      display: flex;
      align-items: center;
      height: 36px;
      font-size: 16px;
      padding: 0 20px;
      &:not(:nth-child(1)) {
        border-top: 1px solid ${(props) => props.theme.gray200};
      }

      &:hover {
        color: ${(props) => props.theme.gray800};
        background-color: ${(props) => props.theme.gray100};
      }
    }
  }
`;

export default function SelectBox({ array, value, changeValue }) {
  const [selectedText, setSelectedText] = useState(value);
  const [selectedValue, setSelectedValue] = useState(value);
  const options = array || [];

  const [active, setActive] = useState(false);
  const fakeSelectRef = useRef(null);
  const fakeSelect = fakeSelectRef.current;

  const toggleSelectBox = () => {
    setActive(!active);
  };
  const selectOption = (e) => {
    setSelectedText(e.target.innerText);
    setSelectedValue(e.target.value);
    setActive(!active);
  };

  const setInitValue = useCallback(() => {
    if (value) setSelectedText(value);
  }, [value]);

  useEffect(() => {
    setInitValue();
  }, [setInitValue]);

  useEffect(() => {
    changeValue(selectedText);
  }, [selectedText]);

  //카테고리 선택 후 필터링, open닫기
  useEffect(() => {
    const openLi = document.querySelector(".open");
    if (openLi) {
      openLi.classList.remove("open");
    }
  }, [selectedValue]);

  //select 외부 눌렀을때 닫기
  const closeSelect = (e) => {
    const targetElement = e.target;
    const isSelect =
      targetElement.classList.contains("select") || targetElement.closest(".select");
    if (isSelect) {
      return;
    }

    setActive(false);
  };
  useEffect(() => {
    const watch = () => {
      document.addEventListener("click", closeSelect);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener("click", closeSelect); // addEventListener 함수를 삭제
    };
  }, [active]);

  // console.log(selectedText);

  return (
    <Wrap onClick={toggleSelectBox} className={active ? `${active} select` : "select"}>
      <div className="selected">
        <div className="selected-value">{selectedText}</div>
      </div>
      {active ? (
        <ul>
          {options.map((item) => (
            <li key={item} onClick={selectOption} value={item} className="option">
              {item}
            </li>
          ))}
        </ul>
      ) : null}
    </Wrap>
  );
}
