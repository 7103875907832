import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Img3 from "../images/pages/ContentGuide/3.png";
import Img4 from "../images/pages/ContentGuide/4.png";
import Img5 from "../images/pages/ContentGuide/5.png";
import Img6 from "../images/pages/ContentGuide/6.png";
import leftArrow from "../images/pages/ContentGuide/left-arr.png";
import rightArrow from "../images/pages/ContentGuide/right-arr.png";

export default function CarouselContentGuide() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  const handleBeforeChange = (current, next) => {
    setCurrentSlide(next);
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow currentSlide slideCount />,
    prevArrow: <PrevArrow currentSlide />,
    responsive: [
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const onWebLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="swiper">
      <Slider
        {...settings}
        // beforeChange={handleBeforeChange}
      >
        <div className="slider_item">
          <div className="img-wrap">
            <img src={Img3} alt="회사소개서" />
          </div>
          <div className="swptext">회사소개서</div>
        </div>
        <div className="slider_item">
          <div className="img-wrap">
            <img src={Img4} alt="카달로그" />
          </div>
          <div className="swptext">카달로그</div>
        </div>
        <div className="slider_item">
          <div className="img-wrap">
            <img src={Img5} alt="홈페이지" />
          </div>
          <div className="swptext">홈페이지</div>
        </div>
        <div className="slider_item">
          <div className="img-wrap">
            <img src={Img6} alt="사진첩" />
          </div>
          <div className="swptext">사진첩</div>
        </div>
      </Slider>
    </div>
  );
}

function NextArrow({ onClick, currentSlide, slideCount }) {
  return (
    // currentSlide !== slideCount - 1 && (
    <div className="arrow right-arrow display-pc" onClick={onClick}>
      <img src={rightArrow} alt="화살표" />
    </div>
    // )
  );
}
function PrevArrow({ onClick, currentSlide }) {
  return (
    // currentSlide !== 0 && (
    <div className="arrow left-arrow display-pc" onClick={onClick}>
      <img src={leftArrow} alt="화살표" />
    </div>
    // )
  );
}
