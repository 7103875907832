/* eslint-disable default-case */
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  getMberEbookStat,
  getMberStatInfos,
  getStatCmtCntListByMonth,
  getStatLikeCntListByMonth,
  getStatSubCntListByMonth,
  getStatViewCntListByMonth,
} from "../core/api";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "../util";
import { StatLineChart } from "./StatLineChart";
import { StatEbookList } from "./StatEbookList";
import SelectBox from "./SelectBox";
import { useMediaQuery } from "react-responsive";
import ModalStatEbookList from "./ModalStatEbookList";
import { StatEbookListAll } from "./StatEbookListAll";

const Wrapper= styled.div`
    font-family: Pretendard-Medium;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;
const Indicators = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 10px;
`;
const Indicator = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #eee;
  cursor: pointer;
  
  &:last-child {
    border-right: none;
  }
  &:hover {
    background-color: #f9f9f9;
  }
  ${({ $active }) => $active && `
    background-color: #f9f9f9;
  `}
`;  
const Item = styled.div`
  font-size: 1rem;
  color: #888;
  margin-bottom: 15px;
`;
const Value = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
const RoundBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px;

  &.stat_graph {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  &:last-child {
    margin-bottom: 40px;
  }
`;

export default function Statistics() {
  
  const { accessToken } = useUser();
  const [ active, setActive ] = useState('totalViewCnt');
  const [ ebookList, setEbookList ] = useState({});
  const [ ebookListAll, setEbookListAll ] = useState({});
  const [ isModifyOpen, setIsModifyOpen] = useState(false);
  const [ totalCnt, setTotalCnt ] = useState(0);
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
    
  //현재 연도/월로 세팅.
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  
  useEffect(() => {
    
    setMyStatEbookParams({...myStatEbookParams, selDate: currentYear+'-'+currentMonth})

  }, []);

  // 연도 선택 및 연도 초기화. (2023년부터 현재년도까지)
  // const array = ['2023', '2024'];
  const array = Array.from({length: new Date().getFullYear() - 2022}, (_, i) => (2023 + i).toString());
  const [selYear, setSelYear] = useState(currentYear);
  const changeValue = (selYear) => {
    setSelYear(selYear);
    setMyStatInfoParams({...myStatInfoParams, startDate: selYear+'-01', endDate: selYear+'-12'});
    setMyStatEbookParams({...myStatEbookParams, selDate: 0});
    myStatInfos.refetch();
  }

    
  /** 
   * 내 통계 지표 정보 조회
  */
  const { data: myStat } = useQuery(
    ["mberEbookStat", accessToken],
    getMberEbookStat
  );

  /**
   * 내 지표별 통계 정보 조회
   */
  const [ myStatInfoParams, setMyStatInfoParams ] = useState({
    statType: 'totalViewCnt',  
    startDate: '2024-01',
    endDate: '2024-12'
  });

  const myStatInfos = useQuery({
    queryKey:["mberStatInfos", myStatInfoParams, accessToken],
    queryFn:()=>getMberStatInfos(myStatInfoParams),
  });


  /**
   * 내 지표별 통계 차트 월별 포인트 클릭시 이북리스트 조회
   */
  const [ myStatEbookParams, setMyStatEbookParams ] = useState({
    selDate: 0,
  });

  
 
  const myViewCntList = useQuery({
    queryKey:["myViewCntList", myStatEbookParams, accessToken],
    queryFn:()=>getStatViewCntListByMonth(myStatEbookParams),
    enabled: false,
  });

  const myLikeCntList = useQuery({
    queryKey:["myLikeCntList", myStatEbookParams, accessToken],
    queryFn:()=>getStatLikeCntListByMonth(myStatEbookParams),
    enabled: false,
  });

  const myCmtCntList = useQuery({
    queryKey:["myCmtCntList", myStatEbookParams, accessToken],
    queryFn:()=>getStatCmtCntListByMonth(myStatEbookParams),
    enabled: false,
  });

  const mySubCntList = useQuery({
    queryKey:["mySubCntList", myStatEbookParams, accessToken],
    queryFn:()=>getStatSubCntListByMonth(myStatEbookParams),
    enabled: false,
  });

  /**
   * 지표 수치 클릭시 누적 리스트로 가져옴.
   */
  const selDateAll  = { selDate: ''};

  const myViewCntListAll = useQuery({
    queryKey:["myViewCntListAll", selDateAll, accessToken],
    queryFn:()=>getStatViewCntListByMonth(selDateAll),
    enabled: false,
  });

  const myLikeCntListAll = useQuery({
    queryKey:["myLikeCntListAll", selDateAll, accessToken],
    queryFn:()=>getStatLikeCntListByMonth(selDateAll),
    enabled: false,
  });

  const myCmtCntListAll = useQuery({
    queryKey:["myCmtCntListAll", selDateAll, accessToken],
    queryFn:()=>getStatCmtCntListByMonth(selDateAll),
    enabled: false,
  });

  const mySubCntListAll = useQuery({
    queryKey:["mySubCntListAll", selDateAll, accessToken],
    queryFn:()=>getStatSubCntListByMonth(selDateAll),
    enabled: false,
  });


  /**
   * 통계 지표 영역 클릭 이벤트
   */
  const handleViewCnt = (e) => {
    // console.log('totalViewCnt');
    setActive('totalViewCnt');
    setMyStatInfoParams({...myStatInfoParams, statType: 'totalViewCnt'});
    setMyStatEbookParams({...myStatEbookParams, selDate: 0});
    myStatInfos.refetch();
  };
  
  const handleLikeCnt = (e) => {
    // console.log('totalLikeCnt');
    setActive('totalLikeCnt');
    setMyStatInfoParams({...myStatInfoParams, statType: 'totalLikeCnt'});
    setMyStatEbookParams({...myStatEbookParams, selDate: 0});
    myStatInfos.refetch();
  }
  const handleCmtCnt = (e) => {
    // console.log('totalCmtCnt');
    setActive('totalCmtCnt');
    setMyStatInfoParams({...myStatInfoParams, statType: 'totalCmtCnt'});
    setMyStatEbookParams({...myStatEbookParams, selDate: 0});
    myStatInfos.refetch();
  }
  const handleSubCnt = (e) => {
    // console.log('totalSubCnt');
    setActive('totalSubCnt');
    setMyStatInfoParams({...myStatInfoParams, statType: 'totalSubCnt'});
    setMyStatEbookParams({...myStatEbookParams, selDate: 0});
    myStatInfos.refetch();
  }

  /**
   * 통계 지표 수치 클릭 이벤트
   */
  const handlePopupViewCnt = (e) => {
    e.stopPropagation();
    // console.log('totalViewCnt');
    setActive('totalViewCnt');
    setIsModifyOpen(true);
    setTotalCnt(myStat?.rows[0].totalViewCnt);
    myViewCntListAll.refetch();
  }

  const handlePopupLikeCnt = (e) => {
    e.stopPropagation();
    // console.log('totalLikeCnt');
    setActive('totalLikeCnt');
    setIsModifyOpen(true);
    setTotalCnt(myStat?.rows[0].totalLikeCnt);
    myLikeCntListAll.refetch();
  }

  const handlePopupCmtCnt = (e) => {
    e.stopPropagation();
    // console.log('totalCmtCnt');
    setActive('totalCmtCnt');
    setIsModifyOpen(true);
    setTotalCnt(myStat?.rows[0].totalCmtCnt);  
    myCmtCntListAll.refetch();  
  }

  const handlePopupSubCnt = (e) => {
    e.stopPropagation();
    // console.log('totalSubCnt');
    setActive('totalSubCnt');
    setIsModifyOpen(true);
    setTotalCnt(myStat?.rows[0].totalSubCnt);        
    mySubCntListAll.refetch();
  }

  const getActiveTitle = () => {
    switch(active){
      case 'totalViewCnt':
        return '총 조회수';
      case 'totalLikeCnt':
        return '총 좋아요';
      case 'totalCmtCnt':
        return '총 댓글수';
      case 'totalSubCnt':
        return '총 구독자수';
    }
  }
  
  /**
   * 차트 그래프 포인트 클릭 이벤트
   */
  useEffect(() => {
    // console.log('chart point click', myStatEbookParams);
    switch(active){
      case 'totalViewCnt':
        myViewCntList.refetch();
        break;
      case 'totalLikeCnt':
        myLikeCntList.refetch();
        break;
      case 'totalCmtCnt':
        myCmtCntList.refetch();
        break;
      case 'totalSubCnt':
        mySubCntList.refetch();
        break;
    }
    
  }, [myStatEbookParams]);

  /**
   * 통계 지표 영역 클릭시 실행
   */
  useEffect(() => {
    //console.log('통계 지표 영역 클릭시 실행');
    switch(active){
      case 'totalViewCnt':
        setEbookList(myViewCntList.data?.rows);
        break;
      case 'totalLikeCnt':
        setEbookList(myLikeCntList.data?.rows);
        break;
      case 'totalCmtCnt':
        setEbookList(myCmtCntList.data?.rows);
        break;
      case 'totalSubCnt':
        setEbookList(mySubCntList.data?.rows);
        break;
    }
  }, [myViewCntList.data, myLikeCntList.data, myCmtCntList.data, mySubCntList.data]);

  /**
  * 통계 지표 수치 클릭시 실행
  */
  useEffect(() => {
    // console.log('통계 지표 수치 클릭시 실행');
    switch(active){
      case 'totalViewCnt':
        setEbookListAll(myViewCntListAll.data?.rows);
        break;
      case 'totalLikeCnt':
        setEbookListAll(myLikeCntListAll.data?.rows);
        break;
      case 'totalCmtCnt':
        setEbookListAll(myCmtCntListAll.data?.rows);
        break;
      case 'totalSubCnt':
        setEbookListAll(mySubCntListAll.data?.rows);
        break;
    }
  }, [myViewCntListAll.isInitialLoading, myLikeCntListAll.isInitialLoading, myCmtCntListAll.isInitialLoading, mySubCntListAll.isInitialLoading,myViewCntListAll.isFetching, myLikeCntListAll.isFetching, myCmtCntListAll.isFetching, mySubCntListAll.isFetching]);

  return (
    <>
      <Wrapper>
        <Indicators>
          <Indicator onClick={handleViewCnt} $active={active==='totalViewCnt'}>
            <Item>총 조회수</Item>
            <Value onClick={handlePopupViewCnt}>{myStat?.rows[0].totalViewCnt}</Value>
          </Indicator>
          <Indicator onClick={handleLikeCnt} $active={active==='totalLikeCnt'}>
            <Item>총 좋아요</Item>
            <Value onClick={handlePopupLikeCnt}>{myStat?.rows[0].totalLikeCnt}</Value>
          </Indicator>
          <Indicator onClick={handleCmtCnt} $active={active==='totalCmtCnt'}>
            <Item>총 댓글수</Item>
            <Value onClick={handlePopupCmtCnt}>{myStat?.rows[0].totalCmtCnt}</Value>
          </Indicator>
          <Indicator onClick={handleSubCnt} $active={active==='totalSubCnt'}>
            <Item>총 구독자수</Item>
            <Value onClick={handlePopupSubCnt}>{myStat?.rows[0].totalSubCnt}</Value>
          </Indicator>
        </Indicators>
        <SelectBox title={'연도선택'} array={array} value={selYear} changeValue={changeValue} />
        <RoundBox className="stat_graph" style={{height:"400px"}}>
        {/* <input type="date" onChange={(e)=>setMyStatEbookParams({...myStatEbookParams, selDate: e.target.value})} style={{height:"30px"}}/> */}
        
          <StatLineChart 
            chartTitle={getActiveTitle()}
            chartData={myStatInfos.data}
            getSelDate={myStatEbookParams}
            setSelDate={setMyStatEbookParams}
            getYear={selYear}
          />
        </RoundBox>
        <RoundBox>
          <StatEbookList 
            active={active} 
            ebookList={ebookList??{}}
          />
        </RoundBox>
      </Wrapper>
      {isModifyOpen ? (
        <ModalStatEbookList
          title={getActiveTitle()}
          width={tablet ? 500 : 800}
          height={tablet ? 650 : 650}
          setIsModifyOpen={setIsModifyOpen}
          totalCnt = {totalCnt}
        >
          <StatEbookListAll 
            active={active} 
            ebookList={ebookListAll??{}}
          />
          </ModalStatEbookList>
        ) : null}
    </>
  
  );
}
