import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useInfiniteScroll } from "../hook/useInfiniteScroll";
import { useTemplateData } from "../hook/useTemplateData";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Container from "../components/Container";
import ThumbGridTemplate from "../components/ThumbGridTemplate";
import EbookContainer from "../components/EbookContainer";
import Spacer from "../components/Spacer";
import Loading from "../components/Loading";
import banner from "../images/pages/ContentGuide/contentGuideBanner.png";
import styled from "styled-components";
import IconContainer from "../components/IconContainer";
import HashtagTemplate from "../components/HashtagTemplate";

const Banner = styled.div`
  width: 1168px;
  height: 318px;

  background: url(${banner}) center / contain no-repeat;
  margin: 10px auto 10px;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    height: auto;
    aspect-ratio: 375/100;
    margin: 0 auto 40px;
  }
`;

const IconList = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  margin: 40px auto;
`;

export default function Template() {
  const location = useLocation();
  const ordering = location.state?.options?.ordering ?? 0;
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, contentData, hasMore } = useTemplateData(ordering, currentPage);
  const { lastElementRef } = useInfiniteScroll(isLoading, hasMore, setCurrentPage);

  return (
    <Layout>
      {isLoading ? <Loading /> : null}
      <HashtagTemplate />
      <Banner/>
      <IconList>
        <IconContainer />
      </IconList>
      <PageTitle
        title="템플릿"
        kind="moreEbooks"
        description="고퀄리티 콘텐츠를 쉽고 빠르게 완성"
      />

      <Spacer height={"0px"} />
      <Container>
        <EbookContainer>
          <ThumbGridTemplate data={contentData} lastElementRef={lastElementRef} />
        </EbookContainer>
      </Container>
    </Layout>
  );
}
